import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useIMask } from 'react-imask'
import cmsBackground from '../../assets/cms-cidade-inteligente.jpeg'

import {
  Container,
  Box,
  Img,
  Input,
  Stack,
  FormControl,
  InputGroup,
  FormHelperText,
  FormErrorMessage,
  Link,
  Button,
  useToast,
  Text
} from '@chakra-ui/react'

import logo from '../../assets/logo.svg'
import { useAuth } from '../../contexts/AuthContext'
import  ForgotPassword from '../ForgotPassword/index'
import Footer from '../../components/Footer'

const Login = () => {
  useEffect(() => {
    console.log(`Ambiente: ${process.env.NODE_ENV}`);
  }, []);

  const toast = useToast()
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState({
    userName: {
      value: '',
      error: false
    },
    password: {
      value: '',
      error: false
    }
  })
  const { login } = useAuth()

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    })

  const isValidateForm = () => {
    let isValid = true
    if (!formValues.userName.value) {
      setFormValues({
        ...formValues,
        userName: { ...formValues.userName, error: true }
      })
      isValid = false
    }

    if (!formValues.password.value) {
      setFormValues({
        ...formValues,
        password: { ...formValues.password, error: true }
      })
      isValid = false
    }

    return isValid
  }

  const handleSubmit = async (e: any) => {
    const userName = formValues.userName.value;
    const senha = formValues.password.value
    e.preventDefault()

    if (isValidateForm()) {
      const res = await login('password', 0, userName, senha, )
      console.log(res)
      if (!res.error) {
        navigate('/cms/inicio')
      } else {
        toast({
          title: 'Usuário não encontrado.',
          description: '',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }

  const navigateToForgotPassword = () => {
    navigate('/forgot-password')
  };

  return (
    <Container
      overflowX="auto"
      color="#000"
      bgImage={`linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${cmsBackground})`}
      bgSize="contain" 
      bgRepeat="no-repeat"
      bgPos="center"
      bgColor="#fff"
      display="flex"
      alignItems="center"
      justifyContent="center"
      w="100%"
      maxWidth="100%"
      maxHeight="100%"
      height="100vh"
      p="0"
      m="0"
    >
      <Box
        width="55vh"
        height="55vh"
        boxShadow="3px 3px 3px rgba(0,0,0, .3)"
        bgColor="#fff"
        borderRadius="4px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        p="0 40px"
      >
        <form onSubmit={handleSubmit}>
          <Stack 
            spacing={4} 
            display="flex"
            direction="column"
            width={{ base: "40vh", sm: "45vh", md: "50vh" }}
            height={{ base: "50vh", sm: "60vh" }}
            justifyContent="center"
            alignItems="center"
          >
            <Img src={logo} m="25px" />
            <Text
              display={'flex'}
              alignItems={'center'}
              justifyContent="center"
              sx={{ mt: '0 !important' }} 
            >
              Sistema de Gestão de Conteúdo
            </Text>
            <FormControl isInvalid={formValues.userName.error}>
              <InputGroup>
                <Input
                  maxLength={80}
                  placeholder='Usuário'
                  aria-label="userName"
                  id="userName"
                  value={formValues.userName.value}
                  onChange={(e) =>
                    handleFieldChange('userName', e.target.value)
                  }
                />
              </InputGroup>
              {formValues.userName.error && (
                <FormErrorMessage>Campo Obrigatório</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={formValues.password.error}>
              <InputGroup>
                <Input
                  type={'password'}
                  placeholder="Senha"
                  value={formValues.password.value}
                  onChange={(e) =>
                    handleFieldChange('password', e.target.value.trim())
                  }
                />
              </InputGroup>
              {formValues.password.error && (
                <FormErrorMessage>Campo Obrigatório</FormErrorMessage>
              )}
              <FormHelperText textAlign="right">
                <Link onClick={navigateToForgotPassword}>Esqueceu a senha?</Link>
              </FormHelperText>
            </FormControl>
            <Button
              borderRadius={0}
              variant="solid"
              color={'#fff'}
              bgColor={"#407494"}
              width="full"
              type="submit"
            >
              Acessar
            </Button>
          </Stack>
        </form>
      </Box>
      <Footer/>
    </Container>
  )
}

export default Login