import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from '@chakra-ui/react';

const convertBreadCrumbNameFromPath = (key: string) => ({
  configuracao: 'Configuração',
  publicacao: 'Publicação',
  'gerenciar-perfil-usuario': 'Gerenciar Perfis de Usuários',
  'editar-perfil-usuario': 'Editar Perfil de Usuário',
  create: 'Incluir novo Usuário',
  'gerenciar-paginas': 'Gerenciar Páginas',
  acoes: 'Ações',
  'adicionar-pagina': 'Incluir Nova Página',
  'midias-sociais': 'Configurações de integrações de redes sociais',
  'gerenciar-rede-social': 'Gerenciar Rede Social',
  'editar-rede-social': 'Editar Rede Social',
  noticia: 'Noticia',
  'servico-comunicacao': 'Serviço de Comunicação',
  'gerenciar-categoria': 'Gerenciar Categorias',
  'inserir-noticia': 'Inserir Notícia',
  category: 'Gerenciar Categorias',
  'criar-categoria': 'Criar Categoria',
  'editar-categoria': 'Editar Categoria',
  aplicativo: 'Gerenciar Aplicativos',
  'novo-aplicativo': 'Incluir Novo Aplicativo',
  'editar-aplicativo': 'Editar Aplicativo',
  banner: 'Gerenciar Banners',
  'novo-banner': 'Incluir Novo Banner',
  'editar-banner': 'Editar Banner',
  'gerenciar-menu': 'Gerenciar menu',
  'inicio': 'Página Inicial',
  'adicionar-usuario': 'Adicionar Usuário',
}[key]);

const SettingsBreadcrumb = () => {
  const { pathname } = useLocation();
  const paths = pathname.split('/').filter(Boolean);

  return (
    <Breadcrumb spacing="8px" separator=">" color="#2883de">
      {paths.slice(1).map((p, index) => {
        const pageName = convertBreadCrumbNameFromPath(p);
        if (!pageName) return null;

        const separator = index !== paths.length - 2 ? '>' : undefined;

        let toLink: string | { pathname: string, state: object } = `/${paths.slice(0, index + 2).join('/')}`;

        if (p === 'editar-rede-social' && paths[index + 2]) {
          const id = paths[index + 2];
          toLink = {
            pathname: `/${paths.slice(0, index + 3).join('/')}`,
            state: { descricaoRede: 'redeSocial', codigoRede: '1' },
          };
        }

        return (
          <BreadcrumbItem key={index + 1} separator={separator}>
            <Link to={toLink}>
              <span style={{ color: '#2883de' }}>{pageName}</span>
            </Link>
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default SettingsBreadcrumb;
