import { Flex, Heading, Button, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Box, RadioGroup, Radio } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb';
import CustomInput from '../../../../components/common/Input/input';
import * as api from '../../../../api/categoria';
import Swal from 'sweetalert2';

interface Categoria {
  seqCategoria: number;
  dscCategoria: string;
  datRegistro: string;
  numRegistro: number;
}

function EditarCategoria() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [categoria, setCategoria] = useState<Categoria | null>(null);
  const [formValues, setFormValues] = useState({
    dscCategoria: { value: '', error: false },
    numRegistro: { value: 0, error: false }
  });

  const fetchCategoria = async () => {
    if (!id) return;

    try {
      const data = await api.obterCategoria(Number(id));
      setCategoria(data);

      setFormValues({
        dscCategoria: { value: data.dscCategoria, error: false },
        numRegistro: { value: data.numRegistro, error: false }
      });
    } catch (error) {
      console.error('Erro ao obter categoria:', error);
    }
  };

  useEffect(() => {
    fetchCategoria();
  }, [id]);

  const isFormValid = () => {
    const { dscCategoria } = formValues;
    return dscCategoria.value.trim() && dscCategoria.value.length >= 5;
  };

  const handleFieldChange = (field: string, value: any) => {
    setFormValues({
      ...formValues,
      [field]: { error: false, value }
    });
  };

  const handleSubmit = async () => {
    const { dscCategoria, numRegistro } = formValues;

    const params = {
      dscCategoria: dscCategoria.value || null,
      numRegistro: numRegistro.value
    };

    try {
      await api.editarCategoria(Number(id), params);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Categoria editada com sucesso.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/configuracao/gerenciar-categoria');
      });
    } catch (error) {
      console.error('Erro ao editar usuário:', error);
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro!',
        text: 'Erro ao editar categoria. Tente novamente mais tarde.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/configuracao/gerenciar-categoria');
      });
    }
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" mb="20px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Editar Categoria
      </Heading>
      <div className="row mb-3">
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Descrição</FormLabel>
            <CustomInput
              value={formValues.dscCategoria.value}
              onChange={(e) => handleFieldChange('dscCategoria', e.target.value)}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Situação</FormLabel>
            <RadioGroup 
              mt={3.5} 
              onChange={(value) => handleFieldChange('numRegistro', Number(value))} 
              value={formValues.numRegistro.value.toString()}
            >
              <Radio value="1" mr={5}>Ativo</Radio>
              <Radio value="0">Inativo</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <Box alignItems={'flex-end'}>
        <Button m="px" onClick={() => navigate(-1)}>
          Desistir
        </Button>
        <Button
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={handleSubmit}
          isDisabled={!isFormValid()}
        >
          Editar Categoria
        </Button>
      </Box>
    </Flex>
  );
}

export default EditarCategoria;
