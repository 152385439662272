import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Icon,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface Categoria {
  seqCategoria: number;
  dscCategoria: string;
  datRegistro: string;
  numRegistro: number;
}

interface TabelaCategoriaProps {
  listaCategoria: Categoria[];
  listaVazia: string;
  loading: boolean;
}

const TabelaCategoria = ({ listaCategoria, listaVazia, loading }: TabelaCategoriaProps) => {
  const navigate = useNavigate();
  
  const renderRows = () =>
    listaCategoria.length > 0 ? (
    listaCategoria.map((categoria) => (
      <Tr key={categoria.seqCategoria}>
        <Td>{categoria.seqCategoria}</Td>
        <Td>{categoria.dscCategoria}</Td>
        <Td>{categoria.datRegistro.split('-').reverse().join('/')}</Td>
        <Td>{categoria.numRegistro === 1 ? "Ativo" : "Inativo"}</Td>
        <Td>
          <IconButton
            aria-label="Botão Editar"
            bgColor="#fff"
            onClick={() => navigate(`editar-categoria/${categoria.seqCategoria}`)}
          >
            <Icon as={MdEdit} fontSize="24px" color="#1789FC" />
          </IconButton>
        </Td>
      </Tr>
    ))
  ) : (
    <Tr>
      <Td colSpan={5} textAlign="center">{listaVazia}</Td>
    </Tr>
  );

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Descrição</Th>
            <Th>Data de Registro</Th>
            <Th>Ativo</Th>
            <Th>Editar</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            <Tr>
              <Td colSpan={5}>
                <Center>
                  <Spinner size="lg" color="blue.500" />
                </Center>
              </Td>
            </Tr>
          ) : (
            renderRows()
          )}
        </Tbody>    
      </Table>
    </TableContainer>
  );
};

export default TabelaCategoria;
