import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Modal } from 'react-bootstrap';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; 
import CustomInput from '../../../components/common/Input/input';
import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb';
import 'ckeditor5/ckeditor5.css';
import './editar-noticia.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Flex,
  Heading,
  Box,
  Button,
  FormLabel,
  FormControl,
  Select,
  Tooltip,
  Stack,
  Checkbox,
  IconButton,
} from '@chakra-ui/react';
import { ImageDropzone } from '../../PublishContent/components/Banner/imageDropzone';
import { FileWithPath } from 'react-dropzone';
import * as api from '../../../api/noticia';
import CustomTextArea from '../../../components/common/Textarea/textArea';
import { FaInfoCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const EditarNoticia: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const today = new Date().toISOString().split('T')[0];

    const [formValues, setFormValues] = useState<{
    titulo: { value: string; error: boolean };
    data: { value: typeof today; error: boolean };
    dataFim: { value: ''; error: boolean };
    imagem: { value: string; error: boolean };
    dscResumo: { value: string; error: boolean };
    endereco: { value: string; error: boolean };
    creditoAutorNoticia: { value: string; error: boolean };
    creditoAutorImagem: { value: string; error: boolean };
    texto: { value: string; error: boolean };
    seqCategoria: { value: any; error: boolean };
    paginaId: { value: any[]; error: boolean };

  }>({
    titulo: { value: '', error: false },
    data: { value: today, error: false },
    dataFim: { value: '', error: false },
    imagem: { value: '', error: false },
    dscResumo: { value: '', error: false },
    endereco: { value: '', error: false },
    creditoAutorNoticia: { value: '', error: false },
    creditoAutorImagem: { value: '', error: false },
    texto: { value: '', error: false },
    seqCategoria: { value: '', error: false },
    paginaId: { value: [], error: false },

  });

  const [imageBase64, setImageBase64] = useState<string>('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [paginas, setPaginas] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [temporaryTexto, setTemporaryTexto] = useState<string>('');
  const [isModalTextOpen, setIsModalTextOpen] = useState<boolean>(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);
  const [removedPages, setRemovedPages] = useState<number[]>([]);
  const [categorias, setCategorias] = useState<any[]>([]);

  const fetchNoticiaData = async () => {
    try {
      const bannerData = await api.obterNoticia(Number(id));
      const { nomePublicacao, datPublicacao, datDespublicar, dscCreditoImagem, dscCreditoNoticia, seqCategoria, conteudoPaginaDTO, binPublicacao, urlBin, dscResumo, dscPublicacao } = bannerData;

      setFormValues({
        titulo: { value: nomePublicacao, error: false },
        data: { value: datPublicacao || today, error: false },
        dataFim: { value: datDespublicar || '', error: false },
        imagem: { value: binPublicacao || '', error: false },
        endereco: { value: urlBin || '', error: false },
        creditoAutorImagem: { value: dscCreditoImagem || '', error: false },
  
        seqCategoria: { value: seqCategoria || '', error: false },
        paginaId: { value: conteudoPaginaDTO.map((item: any) => item.seqPagina.toString()), error: false },
        dscResumo: { value: dscResumo || '', error: false },
        creditoAutorNoticia: { value: dscCreditoNoticia || '', error: false },
        texto: { value: dscPublicacao || '', error: false },
      });

      if (binPublicacao) {
        setImageBase64(binPublicacao);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do banner:", error);
    }
  };

  const fetchPaginas = async () => {
    try {
      const data = await api.paginasAtivas();
      setPaginas(data);
    } catch (error) {
      console.error('Erro ao buscar páginas ativas:', error);
    }
  };
  

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const data = await api.listarCategoriasAtivas();
        setCategorias(data);
      } catch (error) {
        console.error('Erro ao buscar categorias ativas:', error);
      }
    };

    fetchCategorias();
    fetchNoticiaData();
    fetchPaginas();
  }, []);

  const handleDeletePages = async () => {
    try {
      for (const seqPagina of removedPages) {
        await api.deletarRelacionamentoPagina(Number(id), seqPagina);
      }
  
      setRemovedPages([]);
      console.log('Páginas removidas com sucesso!');
    } catch (error) {
      console.error('Erro ao remover páginas:', error);
    }
  };

  const handleFieldChange = (field: keyof typeof formValues, value: any) => {
    setFormValues({ ...formValues, [field]: { value, error: false } });
  };

  const handleCheckboxChange = (seqPagina: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
  
    setSelectedPages((prev) => {
      const updatedPages = isChecked ? [...prev, seqPagina] : prev.filter((id) => id !== seqPagina);
      return updatedPages;
    });
  
    if (isChecked) {
      setRemovedPages(prev => prev.filter(id => id !== seqPagina));
    } else {
      setRemovedPages(prev => [...prev, seqPagina]);
    }
  
    setFormValues((prevValues: any) => {
      const updatedPaginaIdValue = isChecked
        ? [...prevValues.paginaId.value, seqPagina.toString()]
        : prevValues.paginaId.value.filter((id: string) => id !== seqPagina.toString());
  
      return {
        ...prevValues,
        paginaId: { value: updatedPaginaIdValue },
      };
    });
  };  
  

  const handleSaveText = () => {
      handleFieldChange('texto', temporaryTexto);
      setIsModalTextOpen(false);
  };

  const handleOpenModal = () => {
    setTemporaryTexto(formValues.texto.value);
    setIsModalTextOpen(true);
  };

  useEffect(() => {
    const isAutorValid = formValues.creditoAutorNoticia.value.trim() !== '';
    setIsSaveButtonDisabled(!isAutorValid || !temporaryTexto);
  }, [formValues.creditoAutorNoticia.value, temporaryTexto]);

  const handleImageSelected = async (selected: FileWithPath | null) => {
    if (selected) {
      const image = await createImage(selected);
      const resizedImage = await convertTamanhoImagem(image, 300, 300);
      
      if (resizedImage) {
        const base64Image = await convertBase64(resizedImage);
  
        const base64Data = base64Image.split(',')[1];
  
        setImageBase64(base64Data);
      } else {
        setImageBase64('');
        console.error('Erro ao redimensionar a imagem.');
      }
    } else {
      setImageBase64('');
    }
  };

  const handleRemoveImage = () => {
    setImageBase64('');
  };

  const createImage = (file: File) => {
    return new Promise<HTMLImageElement>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => resolve(img);
    });
  };

  const convertTamanhoImagem = (image: HTMLImageElement, width: number, height: number) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    
    console.log(`Dimensões da imagem original: ${image.width}x${image.height}`);
    
    if (ctx) {
      ctx.drawImage(image, 0, 0, width, height);
    }
    
    return new Promise<Blob | null>((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const img = new Image();
          img.src = URL.createObjectURL(blob);
          img.onload = () => {
            console.log(`Dimensões da imagem redimensionada: ${img.width}x${img.height}`);
            resolve(blob);
          };
        } else {
          resolve(null);
        }
      }, 'image/jpeg');
    });
  };

  const convertBase64 = async (file: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      
      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };
      
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const isFormValid = () => {
    const { titulo, data, seqCategoria, paginaId } = formValues;

    const isValid = (
      titulo.value &&
      data.value &&
      seqCategoria.value &&
      (paginaId.value.length > 0)
    );
    // console.log("isFormValid:", {
    //   titulo: titulo.value,
    //   data: data.value,
    //   seqCategoria: seqCategoria.value,
    //   paginaId: paginaId.value.length,
    // });
    return isValid;
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    await handleDeletePages();
    
    const { endereco, texto, creditoAutorImagem, creditoAutorNoticia, dataFim, seqCategoria, paginaId } = formValues;

    if (!endereco.value && !texto.value) {
      setErrorMessage('É necessário informar o "Endereço de internet para a notícia" ou "Texto da notícia" para editar a notícia.');
      setIsErrorModalOpen(true);
      return;
    }

    const params = {
      codTipoPublicacao: 1,
      nomePublicacao: formValues.titulo.value || null,
      datPublicacao: formValues.data.value ? formValues.data.value : null,
      datDespublicar: dataFim.value ? formValues.dataFim.value : null,
      seqUsuario: 124,
      binPublicacao: imageBase64 || null,
      dscResumo: formValues.dscResumo.value || null,
      urlBin: endereco.value || null,
      dscPublicacao: texto.value || null,
      dscCreditoImagem: creditoAutorImagem.value || null, 
      dscCreditoNoticia: creditoAutorNoticia.value || null, 
      seqCategoria: Number(seqCategoria.value) || null,
      conteudoPaginaDTO: paginaId.value.map((id: number) => {
        return {
          seqPagina: id,
          numOrdem: 0,
          datFim: dataFim.value ? formValues.dataFim.value : null,
        };
      }),
    };

    try {
      await api.editarPublicacao(id, params);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Noticia editada com sucesso.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/publicacao/noticia');
      });
    } catch (error) {
      console.error('Erro ao editar noticia:', error);
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro!',
        text: 'Erro ao editar noticia. Tente novamente mais tarde.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/publicacao/noticia');
      });
    }
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      < SettingsBreadcrumb />
      <Heading mt="30px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        editar nova notícia
      </Heading>
      <Box m="40px 0" w="100%">
        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col-md-6 mb-4">
            <FormControl isRequired isInvalid={formValues.seqCategoria.error}>
              <FormLabel mb="8px">Categoria da Notícia</FormLabel>
              <Select 
                placeholder='Selecione uma Categoria' 
                borderColor="gray.300"
                _hover={{ borderColor: 'blue.500' }}
                value={formValues.seqCategoria.value}
                onChange={(e) => handleFieldChange('seqCategoria', e.target.value)}
              >
                {categorias.length > 0 ? (
                  categorias.map(categoria => (
                    <option key={categoria.seqCategoria} value={categoria.seqCategoria}>
                      {categoria.dscCategoria}
                    </option>
                  ))
                ) : (
                  <option disabled>Sem categorias disponíveis</option>
                )}
              </Select>
            </FormControl>
            </div>
            
            <div className="col-md-6 mb-4">
            <FormControl isRequired isInvalid={formValues.titulo.error}>
              <FormLabel mb="8px">Título da Notícia</FormLabel>
              <CustomInput
                value={formValues.titulo.value}
                onChange={(e: any) => handleFieldChange('titulo', e.target.value)}
              />
            </FormControl>
            </div>

            <div className="col-md-6 mb-4">
              <FormControl>
                <FormLabel mb="8px">Endereço de Internet para a notícia</FormLabel>
                <CustomInput
                  value={formValues.endereco.value}
                  onChange={(e) => handleFieldChange('endereco', e.target.value)}
                />
              </FormControl>
            </div>

            <div className="col-md-3 mb-4">
            <FormControl isRequired>
                <FormLabel mb="8px">Data para Publicação</FormLabel>
                <CustomInput
                  id="publication-date"
                  type="date"
                  value={formValues.data.value}
                  onChange={(e) => handleFieldChange('data', e.target.value)}
                  min={today}
                />
              </FormControl>
            </div>
            <div className="col-md-3 mb-4">
              <FormControl>
                <FormLabel mb="8px">Data fim para Publicação</FormLabel>
                <CustomInput
                  id="publication-date"
                  type="date"
                  value={formValues.dataFim.value}
                  onChange={(e) => handleFieldChange('dataFim', e.target.value)}
                  min={today}
                />
              </FormControl>
            </div>

          </div>
          <div className="row mb-3">
            <div className="col-md-6 mb-4">
            <FormControl className="mb-4">
            <FormLabel mb="8px">Imagem miniatura</FormLabel>
                    {imageBase64 ? (
                      <div>
                        <img 
                          src={imageBase64.startsWith('data:image/jpeg;base64,') ? imageBase64 : `data:image/jpeg;base64,${imageBase64}`} 
                          alt="Imagem" 
                          style={{ width: '300px', height: '300px', cursor: 'pointer'}} 
                          onClick={() => document.getElementById('image-upload')?.click()}
                        />
                        <button 
                          type="button" 
                          onClick={() => document.getElementById('image-upload')?.click()}
                          style={{
                            backgroundColor: '#007bff', 
                            color: 'white',             
                            border: 'none',              
                            padding: '10px 15px',      
                            borderRadius: '5px',    
                            cursor: 'pointer',      
                            marginTop: '5px'  
                          }}>
                          Alterar Imagem
                        </button>
                        <button
                          type="button"
                          onClick={handleRemoveImage}
                          style={{
                            backgroundColor: '#007bff', 
                            color: 'white',             
                            border: 'none',              
                            padding: '10px 15px',      
                            borderRadius: '5px',    
                            cursor: 'pointer',      
                            marginLeft: '5px',
                            marginTop: '5px'  
                          }}>
                          Remover Imagem
                        </button>
                      </div>
                    ) : (
                      <div>
                        <button 
                          type="button" 
                          onClick={() => document.getElementById('image-upload')?.click()}
                          style={{ 
                            backgroundColor: '#007bff', 
                            color: 'white',             
                            border: 'none',              
                            padding: '10px 15px',      
                            borderRadius: '5px',    
                            cursor: 'pointer',      
                            marginTop: '5px'  
                          }}>
                          Adicionar Nova Imagem
                        </button>
                      </div>
                    )}

                    <input
                      id="image-upload"
                      type="file"
                      style={{ display: 'none' }}
                      accept="image/jpeg, image/png"
                      onChange={(e) => handleImageSelected(e.target.files ? e.target.files[0] : null)}
                    />

                {imageBase64 && (
                  <FormControl isRequired className="mt-3">
                    <FormLabel mb="8px">Crédito/Autor da imagem</FormLabel>
                    <CustomInput
                      value={formValues.creditoAutorImagem.value}
                      onChange={(e) => handleFieldChange('creditoAutorImagem', e.target.value)}
                    />
                  </FormControl>
                )}
              </FormControl>
              <input
                id="image-upload"
                type="file"
                style={{ display: 'none' }}
                accept="image/jpeg, image/png"
                onChange={(e) => handleImageSelected(e.target.files ? e.target.files[0] : null)}
              />
              <FormControl>
                <Flex justify="space-between" align="center" mb="8px">
                  <FormLabel mb="8px">
                    Escolha em quais páginas a notícia deve aparecer <span style={{ color: 'red' }}>*</span>
                  </FormLabel>
                </Flex>
                <div className="sidebar-checkbox-pagina">
                  <Stack spacing={5}>
                    {paginas.length > 0 ? (
                      paginas.map((pagina: any) => (
                        <Flex key={pagina.seqPagina} align="center" justify="space-between" direction={{ base: 'column', md: 'row' }}>
                          <Checkbox
                            className="checkPaginasNoticia"
                            isChecked={formValues.paginaId.value.includes(pagina.seqPagina.toString())}
                            onChange={(e) => handleCheckboxChange(pagina.seqPagina, e)}
                          >
                            {pagina.nomPagina}
                          </Checkbox>
                        </Flex>
                      ))
                    ) : (
                      <p>Não há páginas disponíveis.</p>
                    )}
                  </Stack>
                </div>
              </FormControl>
            </div>
            <div className="col-md-6 mb-4">
            <FormControl>
            <FormLabel mb="8px">Resumo da Notícia</FormLabel>
            <CustomTextArea
              value={formValues.dscResumo.value}
              onChange={(e) => handleFieldChange('dscResumo', e.target.value)}
              height={'90px'}
            />
          </FormControl>

              <Button
                onClick={handleOpenModal}
                variant="outline"
                colorScheme="blue"
                size="lg"
                fontSize="md"
                borderRadius="md"
                _hover={{ bg: "blue.500", color: "white" }}
                mt={3}
              >
                Texto da Notícia
              </Button>
            </div>
          </div>

          <div className="row mb-3">
          <div className="col-md-6 mb-4">
          </div>

            
          </div>

          <Flex mt="35px" justifyContent="end">
            <Button
              m="6px"
              onClick={() => navigate('/cms/publicacao/noticia')}
            >
              Desistir
            </Button>
            <Button
              type="submit"
              m="6px"
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              isDisabled={!isFormValid()}
            >
              Editar Notícia
            </Button>
          </Flex>
        </form>
      </Box>

      <Modal show={isErrorModalOpen} onHide={() => setIsErrorModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setIsErrorModalOpen(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isModalTextOpen} onHide={() => setIsModalTextOpen(false)} size="xl" enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>Digite o texto da notícia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl>
          <CKEditor
              editor={ClassicEditor}
              data={temporaryTexto}
              config={{
                language: 'pt-br',
                toolbar: [
                  'undo', 'redo', '|',
                  'heading', 'bold', 'italic', 'underline', 'strikethrough', '|',
                  'link', '|',
                  'blockQuote', 'insertTable', '|',
                  'horizontalLine',
                ],
                heading: {
                  options: [
                    {
                      model: 'paragraph',
                      title: 'Texto normal',
                      class: 'ck-heading_paragraph'
                    },
                    {
                      model: 'heading1',
                      view: 'h1',
                      title: 'Cabeçalho 1',
                      class: 'ck-heading_heading1'
                    },
                    {
                      model: 'heading2',
                      view: 'h2',
                      title: 'Cabeçalho 2',
                      class: 'ck-heading_heading2'
                    },
                    {
                      model: 'heading3',
                      view: 'h3',
                      title: 'Cabeçalho 3',
                      class: 'ck-heading_heading3'
                    },
                  ]
                },
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setTemporaryTexto(data);
              }}
            />
          </FormControl>
          {temporaryTexto && (
            <FormControl isRequired className="mt-3 mb-4">
              <FormLabel>Crédito/Autor da notícia</FormLabel>
              <CustomInput
                value={formValues.creditoAutorNoticia.value}
                onChange={(e) => handleFieldChange('creditoAutorNoticia', e.target.value)}
                maxLength={100}
              />
            </FormControl>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalTextOpen(false)}>
            Desistir
          </Button>
          <Button variant="primary" onClick={handleSaveText} disabled={isSaveButtonDisabled}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </Flex>
  );
};

export default EditarNoticia;
