import { Flex, Heading, Button, FormControl, Spinner } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare, faInstagram, faLinkedin, faYoutube,
  faTiktok, faXTwitter, faWhatsapp, faTelegram, faTumblrSquare,
  IconDefinition
} from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import * as api from '../../api/redesSociais';
import SettingsBreadcrumb from '../../components/SettingsBreadcrumb';

interface RedeSocial {
  codRede: number;
  dscRede: string;
}

const iconData: { [key: number]: { icon: IconDefinition; color: string } } & { default: { icon: IconDefinition; color: string } } = {
  0: { icon: faFacebookSquare, color: "#1789fc" },
  1: { icon: faInstagram, color: "#ec1393" },
  2: { icon: faLinkedin, color: "#1DA1F2" },
  3: { icon: faYoutube, color: "#ff0000" },
  4: { icon: faTiktok, color: "#080404" },
  5: { icon: faXTwitter, color: "#080404" },
  6: { icon: faWhatsapp, color: "#43e460" },
  7: { icon: faTelegram, color: "#088ccc" },
  8: { icon: faTumblrSquare, color: "#384c5c" },
  default: { icon: faGlobe, color: "#3b87d8" }
};

function PublishContent() {
  const navigate = useNavigate();
  const [listagemIcones, setListagemIcones] = useState<RedeSocial[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchRedeSocial = async () => {
      setLoading(true);
      try {
        const data = await api.listarIconesRedesSociais(1, 10);
        setListagemIcones(data.formatoArquivos);
      } catch (error) {
        console.error('Erro ao buscar ícones de redes sociais:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRedeSocial();
  }, []);

  const getIconAndColor = (codRede: number) => {
    return iconData[codRede] || iconData.default;
  };

  const handleNavigate = (rede: RedeSocial) => {
    navigate('gerenciar-rede-social', {
      state: {
        descricaoRede: rede.dscRede,
        codigoRede: rede.codRede
      }
    });
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" mb="20px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Configurações de integrações de redes sociais
      </Heading>

      {loading ? (
        <Flex
          justify="center"
          align="center"
          direction="column"
          w="70%"
          h="90%"
          position='fixed'
        >
          <Spinner size="xl" color="blue.500" />
        </Flex>
      ) : (
        <div className="containerSocial">
          <ul className="configuracoesList d-flex justify-content-start flex-wrap w-100 p-0">
            {listagemIcones.map((rede: RedeSocial) => {
              const { icon, color } = getIconAndColor(rede.codRede);
              return (
                <li key={rede.codRede.toString()} style={{ marginRight: '35px' }} className="teste d-flex flex-column justify-content-between align-content-center mr-4 mt-4">
                  <FormControl p={'5px'}>
                    <Flex alignItems="center">
                      <Button
                        type="submit"
                        m="6px"
                        w={'150px'}
                        h={'150px'}
                        color="#000"
                        bgColor="#f9f9f9"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="space-evenly"
                        border="0.5px solid #EAEDF3"
                        borderRadius="10px"
                        boxShadow="0px 2px 3px #e5e5e5"
                        transition="transform 0.2s ease, box-shadow 0.2s ease"
                        _hover={{
                          opacity: 0.8,
                          transform: 'scale(0.95)',
                          boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)'
                        }}
                        onClick={() => handleNavigate(rede)}
                      >
                        <FontAwesomeIcon
                          icon={icon}
                          fontSize={70}
                          color={color}
                        />
                        <p>{rede.dscRede}</p>
                      </Button>
                    </Flex>
                  </FormControl>
                </li>
              );
            })}
          </ul>
        </div>
      )}

    </Flex>
  );
}

export default PublishContent;
