import { AiOutlineHome } from 'react-icons/ai';
import { FiSettings } from 'react-icons/fi';
import { IoLogOutOutline, IoSettingsOutline } from 'react-icons/io5';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import {
  Container,
  Flex,
  Box,
  Center,
  Text,
  Img,
  Circle,
  List,
  ListItem,
  Button,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { Profile, Content, SocialMedia, Calendar, Library, Reports } from '../../components/Icons';
import { useAuth } from '../../contexts/AuthContext';
import './sidebar.scss';
import Swal from 'sweetalert2';

const menu = [
  // {
  //   label: 'Perfil',
  //   icon: (color: string) => <Profile stroke={color} fontSize="28px" />,
  //   path: '/cms/profile'
  // },
  {
    label: 'Publicação',
    icon: (color: string) => <Content stroke={color} fontSize="28px" />,
    path: '/cms/publicacao'
  },
  // {
  //   label: 'Mídias Sociais',
  //   icon: (color: string) => <SocialMedia stroke={color} fontSize="28px" />,
  //   path: '/cms/social-media'
  // },
  // {
  //   label: 'Calendário',
  //   icon: (color: string) => <Calendar stroke={color} fontSize="28px" />,
  //   path: '/cms/calendar'
  // },
  // {
  //   label: 'Biblioteca',
  //   icon: (color: string) => <Library stroke={color} fontSize="28px" />,
  //   path: '/cms/library'
  // },
  // {
  //   label: 'Relatórios',
  //   icon: (color: string) => <Reports stroke={color} fontSize="28px" />,
  //   path: '/cms/reports'
  // },
  {
    label: 'Configuração',
    icon: (color: string) => <IoSettingsOutline color={color} fontSize="28px" />,
    path: '/cms/configuracao'
  },
  {
    label: 'Sair',
    icon: () => <IoLogOutOutline color="#1789FC" fontSize="28px" />,
    path: 'logout'
  }
];

const Sidebar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const { profileName } = useAuth();

  const abrirModalSairAplicacao = () => {
    Swal.fire({
      icon: 'question',
      title: 'Deseja sair da aplicação?',
      text: 'Você tem certeza que deseja sair?',
      showCancelButton: true,
      confirmButtonColor: '#1789FC',
      confirmButtonText: 'Sim, desejo sair',
      cancelButtonText: 'Não, desejo permanecer',
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogoutConfirm();
      }
    });
  };

  const handleLogoutConfirm = () => {
    logout();
    setIsModalOpen(false);
  };

  return (
    <Container className="sidebar-container">
      <Flex className="sidebar-flex">
        <Center mt={'15px'}>
          <button className="icon-button">
            <Icon 
              fontSize={'30px'}
              color="#1789FC"
              as={AiOutlineHome} 
              onClick={() => navigate(`/cms/inicio`)}
            />
          </button>
        </Center>

        <Box className="profile-section">
          <Flex flexDir="column" justifyContent="center" alignItems="center">
            <Circle size="90px" color="grey">
              <Img src="" />
            </Circle>
            <Text className="profile-name">{profileName}</Text>
            {/* <Text className="profile-role">Prefeito</Text> */}
          </Flex>
        </Box>

        <Box>
          <List>
            {menu.map((m, index) => {
              const isActive = location.pathname.startsWith(m.path);
              return (
                <ListItem className={`menu-item ${isActive ? 'active' : ''}`} key={index}>
                  <button
                    onClick={() => m.path === 'logout' ? abrirModalSairAplicacao() : navigate(m.path)}
                    className={`menu-button ${isActive ? 'active' : ''}`}
                  >
                    {m.icon(isActive ? '#fff' : '#1789FC')}
                    <Text className={`menu-label ${isActive ? 'active' : ''}`}>
                      {m.label}
                    </Text>
                  </button>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Flex>
    </Container>
  );
};

export default Sidebar;
