import { useState, createContext, ReactNode, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';
import { auth } from '../../api/auth';

interface AuthContextType {
  login: (grant_type: any, tipoLogin: number, username: string, password: string) => Promise<{
    error: boolean, access_token
      ?: string, profileName?: string, data?: string
  }>;
  logout: () => void;
  token: string;
  profileName?: string;
}

export const AuthenticationContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string>('');
  const [profileName, setProfileName] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedProfileName = localStorage.getItem('profileName');

      api.defaults.headers['APP_ID'] = '23F49D8B-6885-4470-92D1-41A28C6F401C';
      api.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';

    if (storedProfileName) {
      setProfileName(storedProfileName);
    }
  }, []);

  const login = async (grant_type: any, tipoLogin: number, username: string, password: string) => {
    try {
      const { data } = await auth(grant_type = 'password', tipoLogin = 0, username, password);
      const { access_token
        , profileName } = data;

      localStorage.setItem('token', access_token
      );
      localStorage.setItem('profileName', profileName || '');

      api.defaults.headers.Authorization = `Bearer ${access_token
        }`;
      setToken(access_token
      );
      setProfileName(profileName);

      return {
        error: false, access_token
        , profileName
      };
    } catch (e: any) {
      console.error(e, 'error');
      if (e.response?.status === 404) {
        return { error: true, data: 'Usuário não encontrado' };
      } else if (e.response?.status === 401) {
        return { error: true, data: 'Senha incorreta' };
      } else {
        return { error: true, data: e.message };
      }
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('profileName');
    setToken('');
    setProfileName(undefined);
    navigate('/');
  };

  return (
    <AuthenticationContext.Provider value={{ login, logout, token, profileName }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
};

export default AuthProvider;
