import { Flex, Heading, Button, Grid, GridItem, Input, Box, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import CustomInput from '../../../../components/common/Input/input'
import { inserirCategoria } from '../../../../api/categoria'

function CriarCategoria() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState({
    dscCategoria: {
      value: '',
      error: false
    }
  })

  const isFormValid = () => {
    const { dscCategoria } = formValues;
    return dscCategoria.value.trim() && dscCategoria.value.length >= 5
  };

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    })

    const handleSubmit = async () => {
      const { dscCategoria } = formValues;
  
      const params = {
        dscCategoria: dscCategoria.value || null,
      };
      
      try {
        await inserirCategoria(params);
        setIsSuccessModalOpen(true);
      } catch (error) {
        setErrorMessage('Erro ao criar a categoria. Tente novamente.');
        setIsErrorModalOpen(true);
      }
    };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Incluir Nova Categoria
      </Heading>
      <Grid w="100%" gridTemplateColumns="4fr 4fr 4fr" gap="35px">
        <GridItem>
          <FormControl isRequired>
          <FormLabel mb="8px">Descrição</FormLabel>
            <CustomInput
              value={formValues.dscCategoria.value}
              onChange={(e) => handleFieldChange('dscCategoria', e.target.value)}
              maxLength={60}
            />
          </FormControl>
          <br />
          <Box alignItems={'flex-end'}>
            <Button type="submit" m="px" onClick={() => navigate(-1)}>
              Desistir
            </Button>
            <Button
              type="submit"
              m="6px"
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              onClick={() => {
                handleSubmit();
                // navigate(-1);
              }}
              isDisabled={!isFormValid()}
            >
              Criar Categoria
            </Button>
          </Box>
        </GridItem>
      </Grid>

      <Modal isOpen={isSuccessModalOpen} onClose={() => {
        setIsSuccessModalOpen(false);
        navigate('/cms/configuracao/gerenciar-categoria');
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Categoria criada com sucesso!</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => {
              setIsSuccessModalOpen(false);
              navigate('/cms/configuracao/gerenciar-categoria');
            }}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atenção!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{errorMessage}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setIsErrorModalOpen(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default CriarCategoria;
