import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import CustomInput from '../../../components/common/Input/input';
import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb';
import { FaInfoCircle } from 'react-icons/fa';
import 'ckeditor5/ckeditor5.css';
import './editar-banner.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Flex,
  Heading,
  Box,
  Button,
  FormLabel,
  FormControl,
  Select,
  Stack,
  Checkbox,
  Tooltip,
  IconButton,
} from '@chakra-ui/react';
import { ImageDropzone } from '../../PublishContent/components/Banner/imageDropzone';
import { FileWithPath } from 'react-dropzone';
import * as api from '../../../api/banner';
import Swal from 'sweetalert2';

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const InserirBanner: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const today = new Date().toISOString().split('T')[0];

  const [formValues, setFormValues] = useState<{
    titulo: { value: string; error: boolean };
    data: { value: typeof today; error: boolean };
    dataFim: { value: ''; error: boolean };
    imagem: { value: string; error: boolean };
    urlBanner: { value: string; error: boolean };
    creditoAutorImagem: { value: string; error: boolean };
    ordemBanner: { value: any[]; error: boolean };
    categoriaId: { value: string; error: boolean };
    paginaId: { value: any[]; error: boolean };
  }>({
    titulo: { value: '', error: false },
    data: { value: today, error: false },
    dataFim: { value: '', error: false },
    imagem: { value: '', error: false },
    urlBanner: { value: '', error: false },
    creditoAutorImagem: { value: '', error: false },
    ordemBanner: { value: [], error: false },
    categoriaId: { value: '', error: false },
    paginaId: { value: [], error: false },
  });

  const [imageBase64, setImageBase64] = useState<string>('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [categorias, setCategorias] = useState<any[]>([]);
  const [ordem, setOrdem] = useState<any[]>([]);
  const [paginaAtiva, setPaginaAtiva] = useState<any[]>([]);
  const [paginas, setPaginas] = useState<any[]>([]);
  const [ordemValida, setOrdemValida] = useState(true);
  const [ordemRecomendada, setOrdemRecomendada] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);
  const [removedPages, setRemovedPages] = useState<number[]>([]);
  const [modalMessage, setModalMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchBannerData = async () => {
    try {
      const bannerData = await api.obterBanner(Number(id));
      const { nomePublicacao, datPublicacao, datDespublicar, dscCreditoImagem, seqCategoria, conteudoPaginaDTO, binPublicacao, urlBin } = bannerData;

      const paginas = conteudoPaginaDTO.map((item: any) => item.seqPagina);
      const ordens = conteudoPaginaDTO.map((item: any) => item.numOrdem);

      setFormValues({
        titulo: { value: nomePublicacao, error: false },
        data: { value: datPublicacao || today, error: false },
        dataFim: { value: datDespublicar || '', error: false },
        imagem: { value: binPublicacao || '', error: false },
        urlBanner: { value: urlBin || '', error: false },
        creditoAutorImagem: { value: dscCreditoImagem || '', error: false },
        ordemBanner: {
          value: conteudoPaginaDTO.map((item: any) => ({
            seqPagina: item.seqPagina,
            numOrdem: item.numOrdem,
          })), error: false
        },
        categoriaId: { value: seqCategoria.toString(), error: false },
        paginaId: { value: paginas, error: false },
      });

      if (binPublicacao) {
        setImageBase64(binPublicacao);
      }

      if (conteudoPaginaDTO) {
        setOrdem(conteudoPaginaDTO[0].numOrdem);
        setPaginaAtiva(conteudoPaginaDTO[0].seqPagina);
      }
    } catch (error) {
      console.error("Erro ao buscar dados do banner:", error);
    }
  };

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const data = await api.listarCategoriasAtivas();
        setCategorias(data);
      } catch (error) {
        console.error('Erro ao buscar categorias ativas:', error);
      }
    };

    fetchBannerData();
    fetchCategorias();
    fetchPaginas();
  }, []);

  useEffect(() => {
    const validarOrdem = async () => {
      if (!formValues.ordemBanner.value || formValues.ordemBanner.value.length === 0 || formValues.ordemBanner.value.some((item: any) => item.numOrdem === '')) {
        setOrdemValida(true);
        setOrdemRecomendada(null);
        return;
      }

      setLoading(true);

      try {
        const ordensExistentes = await Promise.all(
          formValues.ordemBanner.value.map((item: any) => {
            return api.validarOrdemDisponiveis(item.seqPagina);
          })
        );

        const ordensValidas = ordensExistentes?.every((item: any) => item.valida) || false;

        if (ordensValidas) {
          setOrdemValida(true);
          setOrdemRecomendada(null);
        } else {
          setOrdemValida(false);

          const seqPagina = ordensExistentes[0]?.seqPagina;
          if (seqPagina) {
            const recomendada = calcularOrdemRecomendada(ordensExistentes);
            const errorMessage = `A ordem fornecida não está disponível. É recomendado usar o número ${recomendada}.`;

            setErrorMessage(errorMessage);
            setOrdemRecomendada(recomendada);
            setIsErrorModalOpen(true);
          }
        }
      } catch (error) {
        console.error('Erro ao validar ordem:', error);
        setOrdemValida(false);
        setOrdemRecomendada(null);
        setErrorMessage('Houve um erro ao validar a ordem. Tente novamente mais tarde.');
        setIsErrorModalOpen(true);
      } finally {
        setLoading(false);
      }
    };

    if (formValues.ordemBanner.value?.some((item: any) => /^\d+$/.test(item.numOrdem))) {
      validarOrdem();
    }
  }, [formValues.ordemBanner.value]);

  const fetchPaginas = async () => {
    try {
      const data = await api.paginasAtivas();
      setPaginas(data);
    } catch (error) {
      console.error('Erro ao buscar páginas ativas:', error);
    }
  };

  const handleDeletePages = () => {
    removedPages.forEach((seqPagina) => {
      api.deletarRelacionamentoPagina(Number(id), seqPagina);
    });
  };

  const calcularOrdemRecomendada = (ordensExistentes: any) => {
    const ordens = ordensExistentes.map((item: any) => item.numOrdem);
    const maiorOrdem = Math.max(...ordens);
    const ordemRecomendada = ordens.includes(maiorOrdem) ? maiorOrdem + 1 : maiorOrdem;

    return ordemRecomendada;
  };

  const modalOrdem = () => {
    setLoading(true);
    setOrdemValida(false);
    setOrdemRecomendada(3);

    setModalMessage(`A ordem fornecida não está disponível. ${ordemRecomendada ? `É recomendado usar o número ${ordemRecomendada}.` : ''}`);
    setLoading(false);
    setIsModalOpen(true);
  };

  const handleFieldChange = (field: keyof typeof formValues, value: any) => {
    setFormValues({ ...formValues, [field]: { value, error: false } });
  };

  const handleCheckboxChange = (seqPagina: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    setSelectedPages((prev) => {
      return isChecked
        ? [...prev, seqPagina]
        : prev.filter((id) => id !== seqPagina);
    });

    setRemovedPages((prev) => {
      return isChecked
        ? prev.filter((id) => id !== seqPagina)
        : [...prev, seqPagina];
    });

    setFormValues((prevValues: any) => {
      const updatedPaginaIdValue = isChecked
        ? [...prevValues.paginaId.value, seqPagina.toString()]
        : prevValues.paginaId.value.filter((id: string) => id !== seqPagina.toString());

      let updatedOrdemBanner = [...prevValues.ordemBanner.value];

      if (isChecked) {
        updatedOrdemBanner.push({ seqPagina, numOrdem: '' });
      } else {
        updatedOrdemBanner = updatedOrdemBanner.filter((item: any) => item.seqPagina !== seqPagina);
      }

      return {
        ...prevValues,
        paginaId: { value: updatedPaginaIdValue },
        ordemBanner: { value: updatedOrdemBanner, error: prevValues.ordemBanner.error },
      };
    });
  };


  const handleImageSelected = async (selected: FileWithPath | null) => {
    if (selected) {
      const image = await createImage(selected);
      const resizedImage = await convertTamanhoImagem(image, 1200, 400);

      if (resizedImage) {
        const base64Image = await convertBase64(resizedImage);

        const base64Data = base64Image.split(',')[1];

        setImageBase64(base64Data);
      } else {
        setImageBase64('');
        console.error('Erro ao redimensionar a imagem.');
      }
    } else {
      setImageBase64('');
    }
  };

  const createImage = (file: File) => {
    return new Promise<HTMLImageElement>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => resolve(img);
    });
  };

  const convertTamanhoImagem = (image: HTMLImageElement, width: number, height: number) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(image, 0, 0, width, height);
    }

    return new Promise<Blob | null>((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const img = new Image();
          img.src = URL.createObjectURL(blob);
          img.onload = () => {
            resolve(blob);
          };
        } else {
          resolve(null);
        }
      }, 'image/jpeg');
    });
  };

  const convertBase64 = async (file: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const isFormValid = () => {
    const { titulo, data, creditoAutorImagem, ordemBanner, categoriaId, paginaId } = formValues;

    const isOrdemValida = ordemBanner?.value?.every((item: any) => item.numOrdem && item.numOrdem !== '0') || false;
    const isPaginaIdValido = Array.isArray(paginaId.value) && paginaId.value.length > 0;
    const isOrdemBannerValido = Array.isArray(ordemBanner.value) && ordemBanner.value.length > 0;

    // console.log("isFormValid:", {
    //   titulo: titulo.value,
    //   data: data.value,
    //   creditoAutorImagem: creditoAutorImagem.value,
    //   ordemBanner: ordemBanner.value,
    //   categoriaId: categoriaId.value,
    //   paginaId: paginaId.value,
    //   isOrdemValida,
    //   isPaginaIdValido,
    //   isOrdemBannerValido,
    //   imageBase64: imageBase64.trim(),
    // });

    const isValid = (
      titulo.value &&
      data.value &&
      creditoAutorImagem.value &&
      ordemBanner.value &&
      categoriaId.value &&
      isPaginaIdValido &&
      isOrdemBannerValido &&
      imageBase64.trim() !== '' &&
      isOrdemValida
    );

    return isValid;
  };


  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    await handleDeletePages();

    const { urlBanner, creditoAutorImagem, ordemBanner, dataFim, categoriaId, paginaId } = formValues;

    const uniquePaginaIds = Array.from(new Set(paginaId.value));

    const conteudoPaginaDTO = uniquePaginaIds.map((id: number) => {
      const ordem = ordemBanner.value.find((item: any) => item.seqPagina === id);
      return {
        seqPagina: id,
        numOrdem: ordem ? Number(ordem.numOrdem) : null,
        datFim: dataFim.value ? formValues.dataFim.value : null,
      };
    });

    const validConteudoPaginaDTO = conteudoPaginaDTO.filter((item, index, self) => {
      return !(item.numOrdem === null && index === self.length - 1);
    });

    const params = {
      codTipoPublicacao: 3,
      nomePublicacao: formValues.titulo.value || null,
      datPublicacao: formValues.data.value ? formValues.data.value : null,
      datDespublicar: dataFim.value ? formValues.dataFim.value : null,
      seqUsuario: 124,
      binPublicacao: imageBase64 || null,
      urlBin: urlBanner.value || null,
      dscCreditoImagem: creditoAutorImagem.value || null,
      seqCategoria: Number(categoriaId.value) || null,
      conteudoPaginaDTO: validConteudoPaginaDTO,
    };

    try {
      await api.editarBanner(Number(id), params);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Banner editado com sucesso.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/publicacao/banner');
      });
    } catch (error) {
      console.error('Erro ao editar banner:', error);
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro!',
        text: 'Erro ao editar banner. Tente novamente mais tarde.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/publicacao/banner');
      });
    }
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Editar Banner
      </Heading>
      <Box m="40px 0" w="100%">
        <form onSubmit={handleFormSubmit}>
          <div className="row">
            <div className="col-md-6 mb-4">
              <FormControl isRequired isInvalid={formValues.titulo.error}>
                <FormLabel mb="8px">Categoria do Banner</FormLabel>
                <Select
                  placeholder='Selecione uma Categoria'
                  borderColor="gray.300"
                  _hover={{ borderColor: 'blue.500' }}
                  value={formValues.categoriaId.value}
                  onChange={(e) => handleFieldChange('categoriaId', e.target.value)}
                >
                  {categorias.length > 0 ? (
                    categorias.map(categoria => (
                      <option key={categoria.seqCategoria} value={categoria.seqCategoria}>
                        {categoria.dscCategoria}
                      </option>
                    ))
                  ) : (
                    <option disabled>Sem categorias disponíveis</option>
                  )}
                </Select>
              </FormControl>
            </div>

            <div className="col-md-6 mb-4">
              <FormControl isRequired isInvalid={formValues.titulo.error}>
                <FormLabel mb="8px">Título da Publicação</FormLabel>
                <CustomInput
                  value={formValues.titulo.value}
                  onChange={(e: any) => handleFieldChange('titulo', e.target.value)}
                />
              </FormControl>
            </div>

            <div className="col-md-6 mb-4">
              <FormControl>
                <FormLabel mb="8px">URL para tornar banner clicável</FormLabel>
                <CustomInput
                  value={formValues.urlBanner.value}
                  onChange={(e) => handleFieldChange('urlBanner', e.target.value)}
                />
              </FormControl>
            </div>

            <div className="col-md-3 mb-4">
              <FormControl isRequired>
                <FormLabel mb="8px">Data para Publicação</FormLabel>
                <CustomInput
                  id="publication-date"
                  type="date"
                  value={formValues.data.value}
                  onChange={(e) => handleFieldChange('data', e.target.value)}
                  min={today}
                />
              </FormControl>
            </div>
            <div className="col-md-3 mb-4">
              <FormControl>
                <FormLabel mb="8px">Data fim para Publicação</FormLabel>
                <CustomInput
                  id="publication-date"
                  type="date"
                  value={formValues.dataFim.value}
                  onChange={(e) => handleFieldChange('dataFim', e.target.value)}
                  min={today}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-4">
              <FormControl isRequired>
                <FormLabel mb="8px">Imagem do Banner</FormLabel>
                {imageBase64 ? (
                  <div>
                    <img
                      src={imageBase64.startsWith('data:image/jpeg;base64,') ? imageBase64 : `data:image/jpeg;base64,${imageBase64}`}
                      alt="Imagem"
                      style={{ width: '1200px', height: '400px', cursor: 'pointer' }}
                      onClick={() => document.getElementById('image-upload')?.click()}
                    />
                    <button
                      type="button"
                      onClick={() => document.getElementById('image-upload')?.click()}
                      style={{
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        padding: '10px 15px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginTop: '5px'
                      }}>
                      Alterar Imagem
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={() => document.getElementById('image-upload')?.click()}
                      style={{
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        padding: '10px 15px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        marginTop: '5px'
                      }}>
                      Adicionar Nova Imagem
                    </button>
                  </div>
                )}
                <input
                  id="image-upload"
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/jpeg, image/png"
                  onChange={(e) => handleImageSelected(e.target.files ? e.target.files[0] : null)}
                />
              </FormControl>
            </div>
          </div>
          <div className="row">

            <div className="col-md-6 mb-4">
              <FormControl>
                <Flex justify="space-between" align="center" mb="8px">
                  <FormLabel mb="8px">
                    Escolha em quais páginas o banner deve aparecer <span style={{ color: 'red' }}>*</span>
                  </FormLabel>
                  <FormLabel mb="8px">
                    Ordem de exibição <span style={{ color: 'red' }}>*</span>
                    <Tooltip label="Ordem a ser utilizada para exibir o banner nas páginas." aria-label="A informação da ordem de exibição">
                      <IconButton
                        mb={'8px'}
                        icon={<FaInfoCircle />}
                        variant="link"
                        aria-label="Informação"
                        size="sm"
                      />
                    </Tooltip>
                  </FormLabel>
                </Flex>

                <div className="sidebar-checkbox-pagina">
                  <Stack spacing={5}>
                    {paginas.length > 0 ? (
                      paginas.map((pagina: any) => (
                        <Flex
                          key={pagina.seqPagina}
                          align="center"
                          justify="space-between"
                          direction={{ base: 'column', md: 'row' }}
                        >
                          <Checkbox
                            className="checkPaginasNoticia"
                            isChecked={formValues.paginaId.value.includes(pagina.seqPagina)}
                            onChange={(e) => {
                              const isChecked = e.target.checked;

                              setFormValues((prevValues: any) => {
                                const updatedPaginaIdValue = isChecked
                                  ? [...prevValues.paginaId.value, pagina.seqPagina]
                                  : prevValues.paginaId.value.filter((id: number) => {
                                    const shouldRemove = id === pagina.seqPagina;
                                    if (shouldRemove) {
                                      setRemovedPages((prevRemovedPages) => [
                                        ...prevRemovedPages,
                                        pagina.seqPagina,
                                      ]);
                                    }
                                    return !shouldRemove;
                                  });

                                let updatedOrdemBanner = [...prevValues.ordemBanner.value];

                                if (isChecked) {
                                  updatedOrdemBanner.push({ seqPagina: pagina.seqPagina, numOrdem: '' });
                                } else {
                                  updatedOrdemBanner = updatedOrdemBanner.filter(
                                    (item: any) => item.seqPagina !== pagina.seqPagina
                                  );
                                }

                                return {
                                  ...prevValues,
                                  paginaId: { value: updatedPaginaIdValue },
                                  ordemBanner: { value: updatedOrdemBanner, error: prevValues.ordemBanner.error },
                                };
                              });

                              if (isChecked) {
                                setRemovedPages((prevRemovedPages) =>
                                  prevRemovedPages.filter((seqPagina) => seqPagina !== pagina.seqPagina)
                                );
                              }
                            }}
                          >
                            {pagina.nomPagina}
                          </Checkbox>

                          <div>
                            <CustomInput
                              className="input-ordem"
                              isDisabled={!formValues.paginaId.value.includes(pagina.seqPagina)}
                              value={(() => {
                                const ordemItem = formValues.ordemBanner.value.find(
                                  (item: any) => item.seqPagina === pagina.seqPagina
                                );
                                return ordemItem ? ordemItem.numOrdem : '';
                              })()}
                              onChange={(e) => {
                                const value = e.target.value;

                                setFormValues((prevValues: any) => {
                                  const updatedOrdemValue = [...prevValues.ordemBanner.value];
                                  const existingOrderIndex = updatedOrdemValue.findIndex(
                                    (item: any) => item.seqPagina === pagina.seqPagina
                                  );

                                  if (existingOrderIndex > -1) {
                                    updatedOrdemValue[existingOrderIndex] = { seqPagina: pagina.seqPagina, numOrdem: value };
                                  } else {
                                    updatedOrdemValue.push({ seqPagina: pagina.seqPagina, numOrdem: value });
                                  }

                                  return {
                                    ...prevValues,
                                    ordemBanner: { value: updatedOrdemValue, error: prevValues.ordemBanner.error },
                                  };
                                });
                              }}
                              placeholder="Ordem"
                            />
                          </div>
                        </Flex>
                      ))
                    ) : (
                      <p>Não há páginas disponíveis.</p>
                    )}
                  </Stack>
                </div>
              </FormControl>

            </div>
            <div className="col-md-6 mb-4">
              <FormControl isRequired>
                <FormLabel mb="8px">Crédito/Autor da imagem</FormLabel>
                <CustomInput
                  value={formValues.creditoAutorImagem.value}
                  onChange={(e) => handleFieldChange('creditoAutorImagem', e.target.value)}
                />
              </FormControl>
            </div>
          </div>

          <Flex mt="35px" justifyContent="end">
            <Button
              m="6px"
              onClick={() => navigate('/cms/publicacao/banner')}
            >
              Desistir
            </Button>
            <Button
              type="submit"
              m="6px"
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              isDisabled={!isFormValid()}
            >
              Editar Banner
            </Button>
          </Flex>
        </form>
      </Box>

      <Modal show={isErrorModalOpen} onHide={() => setIsErrorModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setIsErrorModalOpen(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Flex>
  );
};

export default InserirBanner;
