import React, { useEffect, useRef, useState } from 'react';
import JqxTreeGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxtreegrid';
import { Flex, Heading, Box, FormControl, FormLabel, Button } from '@chakra-ui/react';
import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb/SettingsBreadcrumb';
import { jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxtreegrid';
import { Modal } from 'react-bootstrap';
import CustomInput from '../../../components/common/Input/input';
import CustomTextArea from '../../../components/common/Textarea/textArea';
import Swal from 'sweetalert2';
import * as api from '../../../api/menu';
import { useNavigate } from 'react-router-dom';

const GerenciarMenu: React.FC = () => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [formData, setFormData] = useState({ dscMenu: '', codMenu: 0, dscAjuda: '' });
  const [isModalOpen, setModalOpen] = useState(false);
  const treeGridRef = useRef<JqxTreeGrid>(null);
  const [menuData, setMenuData] = useState<any[]>([]);

  const columns = [
    { text: 'Menu', dataField: 'dscMenu', align: 'center', width: '100%' },
  ];

  const getSource = () => ({
    datatype: 'json',
    localdata: menuData,
    dataFields: [
      { name: 'codMenu', type: 'number' },
      { name: 'fkCodMenu', type: 'number' },
      { name: 'dscMenu', type: 'string' },
    ],
    id: 'codMenu',
    hierarchy: {
      keyDataField: { name: 'codMenu' },
      parentDataField: { name: 'fkCodMenu' },
    },
  });

  const handleItemSelect = async (event: any) => {
    const rowData = event?.args?.row || null;
    setSelectedItem(rowData);
    setModalOpen(true);

    if (rowData) {
      try {
        const menuData = await api.obterMenu(rowData.codMenu);
        setFormData({ dscMenu: menuData.dscMenu, codMenu: menuData.codMenu, dscAjuda: menuData.dscAjuda || '' });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Erro ao obter dados do menu. Tente novamente mais tarde.',
        }).then(() => {
          setModalOpen(false);
        });
      }
    }
  };

  const handleSave = async () => {
    if (selectedItem) {
      try {
        const updatedData = {
          codMenu: formData.codMenu,
          dscMenu: formData.dscMenu,
          dscAjuda: formData.dscAjuda,
        };

        const savedData = await api.editarMenu(formData.codMenu, updatedData);
        const updatedMenuData = menuData.map(item =>
          item.codMenu === savedData.codMenu ? { ...item, ...savedData } : item
        );
        setMenuData(updatedMenuData);

        setModalOpen(false);

        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Menu editado com sucesso.',
        }).then(() => {
          setModalOpen(false);
        });
      } catch (error) {
        console.error('Erro ao editar menu:', error);

        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Erro ao editar menu. Tente novamente mais tarde.',
        }).then(() => {
          setModalOpen(false);
        });
      }
    }
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const expandAllRows = () => {
    if (treeGridRef.current) {
      treeGridRef.current.expandAll();
    }
  };

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const menus = await api.listarMenus();
        setMenuData(menus);
      } catch (error) {
        console.error('Erro ao listar menus.', error);
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Erro ao listar menus. Tente novamente mais tarde.',
        });
      }
    };

    fetchMenus();
  }, []);

  useEffect(() => {
    expandAllRows();
  }, [isModalOpen]);

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" mb="20px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Gerenciar Menu
      </Heading>

      <JqxTreeGrid
        ref={treeGridRef}
        width={'100%'}
        selectionMode={'singleRow'}
        source={new jqx.dataAdapter(getSource())}
        columns={columns}
        sortable={true}
        onRowSelect={handleItemSelect}
      />

      <Modal show={isModalOpen} onHide={() => {}} backdrop="static" keyboard={false} size="xl">
        <Modal.Header>
          <Modal.Title>Editar Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl id="dscMenu" mb={4}>
            <FormLabel>Descrição do Menu</FormLabel>
            <CustomInput
              value={formData.dscMenu}
              onChange={(e) => setFormData({ ...formData, dscMenu: e.target.value })}
              maxLength={50}
              isInvalid={formData.dscMenu.length > 50}
            />
            {formData.dscMenu.length > 50 && (
              <Box color="red.500" fontSize="sm">A descrição não pode exceder 50 caracteres.</Box>
            )}
          </FormControl>

          <FormControl id="dscAjuda" mb={4}>
            <FormLabel>Campo de Ajuda</FormLabel>
            <CustomTextArea
              height={'200px'}
              value={formData.dscAjuda}
              onChange={(e) => setFormData({ ...formData, dscAjuda: e.target.value })}
              maxLength={100}
              isInvalid={formData.dscAjuda.length > 100}
              variant="filled"
            />
            {formData.dscAjuda.length > 100 && (
              <Box color="red.500" fontSize="sm">O campo de ajuda não pode exceder 100 caracteres.</Box>
            )}
          </FormControl>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline" colorScheme="red" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            variant="solid"
            colorScheme="blue"
            onClick={handleSave}
            isDisabled={formData.dscMenu.length === 0 || formData.dscMenu.length > 50}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </Flex>
  );
};

export default GerenciarMenu;
