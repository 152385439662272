import { Heading, Button, Icon, Stack } from '@chakra-ui/react'

export interface SettingsCardProps {
  icon: any
  buttonText: string
  onClick: () => void
  cardTitle: string
}
const SettingsCard = ({
  icon,
  buttonText,
  onClick,
  cardTitle
}: SettingsCardProps) => {
  return (
    <Stack
      alignItems="center"
      flexDirection="column"
      p="22px"
      w="275px"
      minH="235px"
      color="#3E3F42"
      border="1px solid #EAEDF3"
      bgColor="#fff"
      borderRadius="4px"
      spacing="20px"
      boxShadow="0px 2px 3px #e5e5e5"
      transition="transform 0.2s ease, box-shadow 0.2s ease"
      _hover={{
        transform: 'scale(0.99)',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)'
      }}
      justifyContent="space-between"
    >
      <Icon as={icon} fontSize="45px" color="#1789FC" />
      <Heading fontSize="18px">{cardTitle}</Heading>  
      <Button
        color="#fff"
        bgColor="#1789FC"
        w="100%"
        onClick={onClick}
        m="10px 0"
      >
        {buttonText}
       </Button> {/*#285c6c*/}
    </Stack>
  )
}

export default SettingsCard
