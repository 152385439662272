import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Heading,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react';
import SettingsBreadcrumb from '../../components/SettingsBreadcrumb';
import TabelaNoticia from './tabelaNoticias';
import Paginacao from '../../components/common/paginacao/paginacao';
import { listarNoticias } from '../../api/noticia';

const Noticia = () => {
  const [noticias, setNoticias] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [codTipoPublicacao, setcodTipoPublicacao] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNoticias = async () => {
      setLoading(true);
      try {
        const data = await listarNoticias(codTipoPublicacao, currentPage, itemsPerPage);
    
        if (data && Array.isArray(data.formatoArquivos)) {
          setNoticias(data.formatoArquivos.map((noticia: any) => ({
            seqConteudo: noticia.seqConteudo ?? '',
            nomePublicacao: noticia.nomePublicacao ?? '',
            datRegistro: noticia.datRegistro ?? '',
            datPublicacao: noticia.datPublicacao ?? '',
            datDespublicar: noticia.datDespublicar ?? '',
            numRegistro: noticia.numRegistro ?? '',
            conteudoPaginaDTO: noticia.conteudoPaginaDTO ?? '',
          })));
          setTotalPages(data.totalPaginas ?? 1);
        } else {
          setNoticias([]);
          setTotalPages(1);
        }
      } catch (error) {
        console.error('Erro ao buscar notícias:', error);
      } finally {
        setLoading(false);
      }
    };
    

    fetchNoticias()
  }, [currentPage, itemsPerPage])

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Notícia
      </Heading>

      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          <Button
            color="#fff"
            bgColor="#1789FC"
            onClick={() => navigate('inserir-noticia')}
          >
            Nova Notícia
          </Button>
        </HStack>
      </Box>

      <Box>
        <TabelaNoticia 
          listaNoticia={noticias} 
          listaVazia="Nenhum dado disponível." 
          loading={loading}
        />
      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage}
      />
    </Flex>
  );
};

export default Noticia;
