import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Icon,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface Banner {
  seqBanner: number;
  dscBanner: string;
  datRegistro: string;
  dataPublicacao: string;
  dataFimPublicacao: string;
}

interface TabelaBannerProps {
  listaBanner: Banner[];
  listaVazia: string;
  loading: boolean;
}

const TabelaBanner = ({ listaBanner, listaVazia, loading }: TabelaBannerProps) => {
  const navigate = useNavigate();

  const renderRows = () =>
    listaBanner.length > 0 ? (
      listaBanner.map((banner) => (
        <Tr key={banner.seqBanner}>
          <Td>{banner.dscBanner}</Td>
          <Td>{banner.datRegistro.split('-').reverse().join('/')}</Td>
          <Td>{banner.dataPublicacao.split('-').reverse().join('/')}</Td>
          <Td>{banner.dataFimPublicacao.split('-').reverse().join('/')}</Td>
          <Td>
            <IconButton
              aria-label="Botão Editar"
              bgColor="#fff"
              onClick={() => navigate(`editar-banner/${banner.seqBanner}`)}
            >
              <Icon as={MdEdit} fontSize="24px" color="#1789FC" />
            </IconButton>
          </Td>
        </Tr>
      ))
    ) : (
      <Tr>
        <Td colSpan={5} textAlign="center">{listaVazia}</Td>
      </Tr>
    );

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Título do Banner</Th>
            <Th>Data do Registro</Th>
            <Th>Data Para Publicação</Th>
            <Th>Data Fim da Publicação</Th>
            <Th>Editar</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            <Tr>
              <Td colSpan={5}>
                <Center>
                  <Spinner size="lg" color="blue.500" />
                </Center>
              </Td>
            </Tr>
          ) : (
            renderRows()
          )}
        </Tbody>      
      </Table>
    </TableContainer>
  );
};

export default TabelaBanner;
