import { api } from './';

interface ApiError {
  response?: {
    data: any;
    status: number;
    statusText: string;
  };
}

export const inserirContaRedeSocial = async (params: any) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };

    const response = await api.post('/contasRedes/inserirConta', params, { headers });
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao inserir conta em rede social');
  }
};

export const editarContaRedeSocial = async (id: number, params: any) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await api.put(`/contasRedes/editar/${id}`, params, {headers});
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao editar conta');
  }
};

export const obterContaRedeSocial = async (id: number) => {
  try {
    const response = await api.get(`/contasRedes/obter/${id}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao obter conta');
  }
};

export const listarContasRedesSociais = async (page: number, size: number, codRedeSocial: number) => {
  try {
    const response = await api.get(`/contasRedes/listar?pagina=${page}&tamanhoPagina=${size}&codRedeSocial=${codRedeSocial}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao listar contas');
  }
};

export const listarIconesRedesSociais = async (page: number, size: number) => {
  try {
    const response = await api.get(`/config/redes/listar?pagina=${page}&tamanhoPagina=${size}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao listar os ícones de redes sociais');
  }
};