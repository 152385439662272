import { Flex, Box, Img, Divider } from '@chakra-ui/react';
import logo from '../../assets/logo.svg';
import logoPref from '../../assets/logoPref.svg';
import MenuProfile from './components/menuProfile';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

function Header() {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const abrirModalSairAplicacao = () => {
    Swal.fire({
      icon: 'question',
      title: 'Deseja sair da aplicação?',
      text: 'Você tem certeza que deseja sair?',
      showCancelButton: true,
      confirmButtonColor: '#1789FC',
      confirmButtonText: 'Sim, desejo sair',
      cancelButtonText: 'Não, desejo permanecer',
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogoutConfirm();
      }
    });
  };

  const handleLogoutConfirm = () => {
    logout();
    navigate('/login');
  };

  return (
    <Flex
      bgColor="#fff"
      px="30px"
      height="100%"
      maxHeight="76px"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid #EAEDF3"
      boxShadow="0 1px 5px rgb(152 152 152 / 10%)"
    >
      <Flex flexDir="row" alignItems="center">
        <Box>
          <Img src={logo} />
        </Box>
        <Divider orientation="vertical" mx="10px" borderColor="#D5D5D5" height="30px" />
        <Box>
          <Img ml={'30px'} src={logoPref} />
        </Box>
      </Flex>
      <Box>
        <MenuProfile onLogout={abrirModalSairAplicacao} />
      </Box>
    </Flex>
  );
}

export default Header;
