import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Flex,
  Heading,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react'

import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb';
import TabelaCategoria from './TabelaCategoria/tabela-categoria';
import { listarCategorias } from '../../../api/categoria';
import Paginacao from '../../../components/common/paginacao/paginacao';

const GerenciarCategoria = () => {
  const [categorias, setCategorias] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchCategorias = async () => {
      setLoading(true);
      try {
        const data = await listarCategorias(currentPage, itemsPerPage)
        console.log(data)

        if (data.formatoArquivos && data.formatoArquivos.length > 0) {
          setCategorias(data.formatoArquivos.map((categoria: any) => ({
            seqCategoria: categoria.seqCategoria,
            dscCategoria: categoria.dscCategoria,
            datRegistro: categoria.datRegistro,
            numRegistro: categoria.numRegistro,
          })))
        } else {
          setCategorias([])
        }

        setTotalPages(data.totalPaginas)

      } catch (error) {
        console.error('Erro ao buscar categorias:', error)
      } finally {
        setLoading(false);
      }
    }

    fetchCategorias()
  }, [currentPage, itemsPerPage])


  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Gerenciar Categorias
      </Heading>

      {/* Search bar */}
      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          <Button
            color="#fff"
            bgColor="#1789FC"
            onClick={() => navigate('criar-categoria')}
          >
            Criar Categoria
          </Button>
        </HStack>
      </Box>

      <Box>
        <TabelaCategoria 
          listaCategoria={categorias} 
          listaVazia="Nenhum dado disponível." 
          loading={loading}
          />
      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage}
      />
    </Flex>
  )
}

export default GerenciarCategoria
