import { Flex, Heading } from '@chakra-ui/react'

import SettingsBreadcrumb from '../../components/SettingsBreadcrumb'
import withAuth from '../../contexts/AuthContext/withAuth'
import GridPaginaInicial from './GridPaginaInicial'

const Inicio = () => {
  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Página Inicial
      </Heading>
      <GridPaginaInicial />
    </Flex>
  )
}

export default withAuth(Inicio)
