import { Menu, MenuButton, MenuList, MenuGroup, MenuDivider, MenuItem, Img, Box, Text } from "@chakra-ui/react";
import profile from '../../../../assets/profile.svg';
import { useAuth } from "../../../../contexts/AuthContext";

interface MenuProfileProps {
  onLogout: () => void;
}

function MenuProfile({ onLogout }: MenuProfileProps) {
  const { profileName } = useAuth();

  return (
    <Menu>
      <Box display="flex" alignItems="flex-end" flexDirection="column-reverse" p={4}>
        <MenuButton
          sx={{
            'span': {
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'flex-end',
            }
          }}
          p={0}
          m={0}
          minW={0}
          bg="transparent"
          _focus={{ boxShadow: 'none' }}
          _hover={{ textDecoration: 'none' }}
          _active={{ boxShadow: 'none' }}
        >
          <Img width="30%" src={profile} alt="Profile" />
        </MenuButton>
      </Box>
      <MenuList>
        {/* <Text fontWeight='medium' color={'blue.400'} p={2}>
          {profileName}
        </Text> */}
        {/* <MenuDivider /> */}
          <MenuItem onClick={onLogout}>Sair</MenuItem>
      </MenuList>
    </Menu>
  );
}

export default MenuProfile;
