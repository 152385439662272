import { api } from './';

interface ApiError {
  response?: {
    data: any;
    status: number;
    statusText: string;
  };
}

export const inserirBanner = async (params: any) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await api.post(`/conteudo/inserirPublicacao`, params, {headers});
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao inserir banner');
  }
};

export const editarBanner = async (id: number, params: any) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await api.put(`/conteudo/editar/${id}`, params, {headers});
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao editar banner');
  }
};

export const listarBanner = async (codTipoPublicacao: number, pagina: number, tamanhoPagina: number, ) => {
  try {
    const response = await api.get(`/conteudo/listar?codTipoPublicacao=${codTipoPublicacao}&pagina=${pagina}&tamanhoPagina=${tamanhoPagina}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao listar banner');
  }
};

export const obterBanner = async (id: any) => {
  try {
    const response = await api.get(`/conteudo/obter/${id}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao obter banner');
  }
};

export const deletarRelacionamentoPagina = async (seqConteudo: number, seqPagina: number ) => {
  try {
    const response = await api.delete(`/conteudo/deletarRelacionamento?seqConteudo=${seqConteudo}&seqPagina=${seqPagina}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao deletar relacionamento de página');
  }
};

export const paginasAtivas = async () => {
  try {
    const response = await api.get('/paginas/ativas');
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao listar páginas ativas');
  }
};

export const validarOrdemDisponiveis = async (seqPagina: number) => {
  try {
    const response = await api.get(`/paginas/relacionamentos/${seqPagina}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao validar ordem disponíveis');
  }
};

export const listarCategoriasAtivas = async () => {
  try {
    const response = await api.get('/categoria/ativas');
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao listar categorias ativas');
  }
};