import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Flex,
  Heading,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react'

import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb';
import TabelaUsuario from './tabela-usuario/tabela-usuario';
import * as api from '../../../api/usuario';
import Paginacao from '../../../components/common/paginacao/paginacao';

const GerenciarUsuario = () => {
  const [usuarios, setUsuarios] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchUsuarios = async () => {
      setLoading(true);
      try {
        const data = await api.listarUsuarios(currentPage, itemsPerPage)
        console.log(data)

        if (data.formatoArquivos && data.formatoArquivos.length > 0) {
          setUsuarios(data.formatoArquivos.map((usuario: any) => ({
            seqUsuario: usuario.seqUsuario,
            dscNome: usuario.dscNome,
            dscCpf: usuario.dscCpf,
            datRegistro: usuario.datRegistro,
            datInativo: usuario.datInativo,
            sitRegistro: usuario.sitRegistro,
          })))
        } else {
          setUsuarios([])
        }

        setTotalPages(data.totalPaginas)

      } catch (error) {
        console.error('Erro ao buscar categorias:', error)
      } finally {
        setLoading(false);
      }
    }

    fetchUsuarios()
  }, [currentPage, itemsPerPage])


  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Gerenciar Usuário
      </Heading>

      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          <Button
            color="#fff"
            bgColor="#1789FC"
            onClick={() => navigate('adicionar-usuario')}
          >
            Adicionar Usuário
          </Button>
        </HStack>
      </Box>

      <Box>
        <TabelaUsuario 
          listaUsuario={usuarios} 
          listaVazia="Nenhum dado disponível." 
          loading={loading}
          />
      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage}
      />
    </Flex>
  )
}

export default GerenciarUsuario
