import { FaChartBar, FaExclamationTriangle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Grid, GridItem, Box, Text, Heading } from '@chakra-ui/react'

import CardsInicio from '../Cards/CardsInicio'
import './grid-pagina-inicial.scss'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const GridPaginaInicial = () => {
  const navigate = useNavigate()
  const data = [
        { title: "Avisos de necessidade de atuação", progress: 75, route: "visualizacao-necessidade-atuacao" },
        { title: "Visualizações da cidade inteligente", progress: 45, route: "" },
        { title: "Visualizações de notícias", progress: 60, route: "" },
        { title: "Visualizações de banners", progress: 85, route: "" },
        { title: "Visualizações de eventos", progress: 30, route: "" },
      ];

  return (
    <div className='main-container'>
      {data.map((item, index) => (
          <GridItem key={index}>
            <Box
              p="20px"
              bgColor="#fff"
              borderRadius="8px"
              textAlign="center"
              height= "210px"
              width= "280px"
              boxShadow="0px 2px 3px #e5e5e5"
              transition="transform 0.2s ease, box-shadow 0.2s ease"
              _hover={item.title === "Avisos de necessidade de atuação" ? {
                transform: 'scale(0.99)',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
                cursor: 'pointer',
              } : {
                transform: 'scale(0.99)',
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',}}
              onClick={() => navigate(item.route)}
            >
              <div style={{ width: '110px', height: '110px', margin: '0 auto' }}>
                <CircularProgressbar value={item.progress} text={`${item.progress}`} />
              </div>
              <Heading fontSize="16px" mt="10px" color="#333">
                {item.title}
              </Heading>
            </Box>
          </GridItem>
        ))}
    </div>
  )
}

export default GridPaginaInicial
