import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  Icon,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import { useIMask } from 'react-imask';
import { useNavigate } from 'react-router-dom';

interface Usuario {
  seqUsuario: number;
  dscNome: string;
  dscCpf: string;
  datRegistro: string;
  sitRegistro: number;
  datInativo: string;
}

interface TabelaUsuarioProps {
  listaUsuario: Usuario[];
  listaVazia: string;
  loading: boolean;
}

const TabelaUsuario = ({ listaUsuario, listaVazia, loading }: TabelaUsuarioProps) => {
  const navigate = useNavigate();

  const { ref: cpfRef } = useIMask(
    { mask: '000.000.000-00' },
    // {
    //   onAccept: (value) => handleFieldChange('dscCpf', value)
    // }
  );

  function mascaraCpf(cpf: string) {
    /* eslint-disable-next-line */
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
  }
  
  const renderRows = () =>
    listaUsuario.length > 0 ? (
    listaUsuario.map((usuario) => (      
      <Tr key={usuario.seqUsuario}>
        <Td>{mascaraCpf(usuario.dscCpf)}</Td>
        <Td>{usuario.dscNome}</Td>
        <Td>{usuario.sitRegistro === 1 ? "Ativo" : "Inativo"}</Td>
        <Td>{usuario.datRegistro.split('-').reverse().join('/')}</Td>
        <Td>{
          usuario.datInativo != null ? usuario.datInativo.split('-').reverse().join('/'): 'Não Inativado'
        }</Td>
        <Td>
          <IconButton
            aria-label="Botão Editar"
            bgColor="#fff"
            onClick={() => navigate(`editar-usuario/${usuario.seqUsuario}`)}
          >
            <Icon as={MdEdit} fontSize="24px" color="#1789FC" />
          </IconButton>
        </Td>
      </Tr>
    ))
  ) : (
    <Tr>
      <Td colSpan={5} textAlign="center">{listaVazia}</Td>
    </Tr>
  );

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>CPF</Th>
            <Th>Nome</Th>
            <Th>Situação</Th>
            <Th>Data do Registro</Th>
            <Th>Data de Inativação</Th>
            <Th>Editar</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            <Tr>
              <Td colSpan={5}>
                <Center>
                  <Spinner size="lg" color="blue.500" />
                </Center>
              </Td>
            </Tr>
          ) : (
            renderRows()
          )}
        </Tbody>    
      </Table>
    </TableContainer>
  );
};

export default TabelaUsuario;
