import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import LoggedInArea from '../components/LoggedInArea'
import Calendar from '../pages/Calendar'
import CreatePassword from '../pages/CreatePassword'
import Library from '../pages/Library'
import Login from '../pages/Login'
import Profile from '../pages/Profile'
import PublishContent from '../pages/PublishContent'
import Reports from '../pages/Reports'
import Settings from '../pages/Settings/GerenciarPerfilUsuario'
import GerenciarPerfilUsuario from '../pages/Settings/GerenciarPerfilUsuario/gerenciar-perfil-usuario/gerenciar-perfil-usuario'
import EditarPerfilUsuario from '../pages/Settings/GerenciarPerfilUsuario/editar-perfil-usuario/editar-perfil-usuario'
import SocialMedia from '../pages/SocialMedia'
import ManagePages from '../pages/Settings/SettingPage'
import ActionPage from '../pages/Settings/SettingPage/Actions/ActionPage'
import AddPage from '../pages/Settings/SettingPage/IncludedPages/addPage'
import Comunication from '../pages/Settings/Comunication'
import CreateText from '../pages/PublishContent/components/WhatToPublish/createText'
import CreateVideo from '../pages/PublishContent/components/WhatToPublish/createVideo'
import ForgotPassword from '../pages/ForgotPassword'
import AfterPage from '../pages/ForgotPassword/afterPage'
import ChangePassword from '../pages/ForgotPassword/changePassword'
import Publicacao from '../pages/publicacao/publicacao';
import Noticia from '../pages/noticia/noticia'
import InserirNoticia from '../pages/noticia/inserir-noticia/inserir-noticia'
import EditarNoticia from '../pages/noticia/editar-noticia/editar.noticia'
import GerenciarCategoria from '../pages/Settings/GerenciarCategoria/gerenciar-categoria'
import CriarCategoria from '../pages/Settings/GerenciarCategoria/adicionar-categoria/adicionar-categoria'
import EditarCategoria from '../pages/Settings/GerenciarCategoria/editar-categoria/editar-categoria'
import GerenciarAplicativo from '../pages/Settings/Aplicativo/gerenciar-aplicativo'
import NovoAplicativo from '../pages/Settings/Aplicativo/novoAplicativo/novo-aplicativo'
import EditarAplicativo from '../pages/Settings/Aplicativo/editar-aplicativo/editar-aplicativo'
import Banner from '../pages/banner/gerenciar-banner';
import InserirBanner from '../pages/banner/inserir-banner/inserir-banner';
import EditarBanner from '../pages/banner/editar-banner/editar-banner'
import GerenciarMenu from '../pages/Settings/gerenciar-menu/gerenciar-menu'
import ConfigurarMidia from '../pages/PublishContent/configurarMidia/configurar-midia'
import GerenciarRedesSociais from '../pages/PublishContent/gerenciar-redes-sociais/gerenciar-redes-sociais'
import EditarRedeSocial from '../pages/PublishContent/editar-rede-social/editar-rede-social'
import TelaInicio from '../pages/Inicio/inicio'
import VisualizacaoNecesidadeAtuacao from '../pages/Inicio/Cards/visualizacaoNecessidadeAtuacao/visualizacao-necessidade-atuacao'
import GerenciarUsuario from '../pages/Settings/gerenciar-usuario/gerenciar-usuario'
import AdicionarUsuario from '../pages/Settings/gerenciar-usuario/adicionar-usuario/adicionar-usuario'
import EditarUsuario from '../pages/Settings/gerenciar-usuario/editar-usuario/editar-usuario'

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route index element={<Login />} path="/login" />
      <Route element={<CreatePassword />} path="/create-password" />
      <Route element={<ForgotPassword />} path="/forgot-password" />
      <Route element={<AfterPage />} path="/new-password" />
      <Route element={<ChangePassword />} path="/change-password/:token" />

      <Route element={<LoggedInArea />} path={'/cms'}>
        <Route element={<TelaInicio />} path="/cms/inicio" />
        <Route index element={<PublishContent />} path="configuracao/midias-sociais" />
        <Route element={<CreateText />} path="content/createText" />
        <Route element={<CreateVideo />} path="content/createVideo" />
        <Route element={<Profile />} path="profile" />
        <Route element={<SocialMedia />} path="configuracao/midias-sociais" />
        <Route element={<ConfigurarMidia />} path="configuracao/midias-sociais/configurar-rede-social" />
        <Route element={<GerenciarRedesSociais />} path="configuracao/midias-sociais/gerenciar-rede-social" />
        <Route element={<EditarRedeSocial />} path="configuracao/midias-sociais/gerenciar-rede-social/editar-rede-social/:id" />
        <Route element={<Calendar />} path="calendar" />
        <Route element={<Library />} path="library" />
        <Route element={<Reports />} path="reports" />
        <Route element={<Settings />} path="configuracao" />

        <Route element={<Publicacao />} path="publicacao" />
        <Route element={<Noticia />} path="publicacao/noticia" />
        <Route element={<InserirNoticia />} path="publicacao/noticia/inserir-noticia" />
        <Route element={<EditarNoticia />} path="publicacao/noticia/editar-noticia/:id" />

        <Route element={<ManagePages />} path="configuracao/gerenciar-paginas" />
        <Route element={<AddPage />} path="configuracao/gerenciar-paginas/adicionar-pagina" />
        <Route element={<ActionPage />} path="configuracao/gerenciar-paginas/acoes/:pageName" />

        <Route element={<Comunication />} path="configuracao/servico-comunicacao" />

        <Route element={<GerenciarCategoria />} path="configuracao/gerenciar-categoria" />
        <Route element={<CriarCategoria />} path="configuracao/gerenciar-categoria/criar-categoria" />
        <Route element={<EditarCategoria />} path="configuracao/gerenciar-categoria/editar-categoria/:id" />

        <Route element={<GerenciarAplicativo />} path="configuracao/aplicativo" />
        <Route element={<NovoAplicativo />} path="configuracao/aplicativo/novo-aplicativo" />
        <Route element={<EditarAplicativo />} path="configuracao/aplicativo/editar-aplicativo/:id" />

        <Route element={<GerenciarPerfilUsuario />} path="configuracao/gerenciar-perfil-usuario" />
        <Route element={<EditarPerfilUsuario />} path="configuracao/gerenciar-perfil-usuario/editar-perfil-usuario/:id" />

        <Route element={<GerenciarUsuario />} path="configuracao/gerenciar-usuario" />
        <Route element={<AdicionarUsuario />} path="configuracao/gerenciar-usuario/adicionar-usuario" />
        <Route element={<EditarUsuario />} path="configuracao/gerenciar-usuario/editar-usuario/:id" />
        
        <Route element={<Banner />} path="publicacao/banner" />
        <Route element={<InserirBanner />} path="publicacao/banner/novo-banner" />
        <Route element={<EditarBanner />} path="publicacao/banner/editar-banner/:id" />

        <Route element={<GerenciarMenu />} path="configuracao/gerenciar-menu" />
        
        <Route element={<VisualizacaoNecesidadeAtuacao />} path="inicio/visualizacao-necessidade-atuacao" />

      </Route>
    </Routes>
  )
}

export default AppRoutes
