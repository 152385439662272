import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Flex,
  Heading,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react'

import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb';
import TabelaRedesSociais from './tabela-redes-sociais';
import * as api from '../../../api/redesSociais'
import Paginacao from '../../../components/common/paginacao/paginacao';

const GerenciarRedesSociais = () => {
  const [redes, setRedes] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [redeSocial, setRedeSocial] = useState<String>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate()
  const location = useLocation();

  const { descricaoRede = 'Rede Social', codigoRede = 'defaultCodigo' } = location.state || {};

  useEffect(() => {
    const fetchRedeSocial = async () => {
      setLoading(true);
      try {
        const data = await api.listarContasRedesSociais(currentPage, itemsPerPage, codigoRede)
        console.log(data)

        if (data.formatoArquivos && data.formatoArquivos.length > 0) {
          setRedes(data.formatoArquivos.map((categoria: any) => ({
            seqConfigRede: categoria.seqConfigRede,
            dscUsuario: categoria.dscUsuario,
            datRegistro: categoria.datRegistro,
            sitRegistro: categoria.sitRegistro,
          })))
        } else {
          setRedes([])
        }

        setTotalPages(data.totalPaginas)

      } catch (error) {
        console.error('Erro ao buscar contas em redes sociais:', error)
      } finally {
        setLoading(false);
      }
    }

        fetchRedeSocial();
  }, [currentPage, itemsPerPage])

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Gerenciar Rede Social
      </Heading>

      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          <Button
            color="#fff"
            bgColor="#1789FC"
            onClick={() => navigate(`/cms/configuracao/midias-sociais/configurar-rede-social`, {
              state: {
                descricaoRede: descricaoRede,
                codigoRede: codigoRede
              }
            })}
          >
            Adicionar Rede Social
          </Button>
        </HStack>
      </Box>

      <Box>
        <TabelaRedesSociais 
          listaRedeSocial={redes} 
          listaVazia="Nenhum dado disponível." 
          loading={loading}
        />
      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage}
      />
    </Flex>
  )
}

export default GerenciarRedesSociais
