import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Icon, IconButton, Spinner, Text } from '@chakra-ui/react'
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

type User = {
  dscPerfilAcesso: string
  codPerfilAcesso: string
  numRegistro: number
}

interface TabelaPerfilUsuarioProps {
  listaPerfilUsuario: User[]
  listaVazia: string;
  loading: boolean;
}

const TabelaPerfilUsuario = ({ listaPerfilUsuario, listaVazia, loading }: TabelaPerfilUsuarioProps) => {
  const navigate = useNavigate();

  const renderRows = () =>
    listaPerfilUsuario.length > 0 ? (
      listaPerfilUsuario.map((perfilUsuario) => (
        <Tr key={perfilUsuario.codPerfilAcesso}>
          <Td>{perfilUsuario.codPerfilAcesso}</Td>
          <Td>{perfilUsuario.dscPerfilAcesso}</Td>
          <Td>{perfilUsuario.numRegistro === 1 ? 'Ativo' : 'Inativo'}</Td>
          <Td>
            <IconButton aria-label="Botão Editar" bgColor="#fff">
              <Icon 
                as={MdEdit} 
                fontSize="24px" 
                color="#1789FC"
                onClick={() => navigate(`editar-perfil-usuario/${perfilUsuario.codPerfilAcesso}`)}
              />
            </IconButton>
          </Td>
        </Tr>
      ))
    ) : (
      <Tr>
        <Td colSpan={5} textAlign="center">{listaVazia}</Td>
      </Tr>
    );

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Perfil</Th>
            <Th>Situação</Th>
            <Th>Editar</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            <Tr>
              <Td colSpan={4} textAlign="center">
                <Spinner size="lg" color="blue.500" />
              </Td>
            </Tr>
          ) : (
            renderRows()
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TabelaPerfilUsuario;
