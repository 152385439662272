import axios from 'axios'
const urlAtual = window.location.hostname;
let apiUrl = '';

if(urlAtual == 'cms-dev.acto.net.br' || urlAtual == 'localhost') {
  apiUrl = 'https://cms-back-dev.acto.net.br/'
}
if(urlAtual == 'cms-qa.acto.net.br') {
  apiUrl = 'https://cms-back-qa.acto.net.br/'
}
if(urlAtual == 'cms-demo.acto.net.br') {
  apiUrl = 'https://cms-back-demo.acto.net.br/'
}
if(urlAtual == 'cms-osasco.acto.net.br') {
  apiUrl = 'https://cms-back-osasco.acto.net.br/'
}

console.log('api', apiUrl);
console.log('urlAtual', urlAtual);

export const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*'
  }
})
