import { Flex, Heading, Button, Input, Box, FormControl, FormLabel, Select, RadioGroup, Radio, FormHelperText, FormErrorMessage, Spinner } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import CustomInput from '../../../../components/common/Input/input'
import { useIMask } from 'react-imask';
import * as api from '../../../../api/usuario';
import Swal from 'sweetalert2'

interface Usuario {
  dscCpf: number;
  dscNome: string;
  dscDdd: string;
  dscCelular: number;
  dscEmail: number;
  dscSenha: number;
  codPerfilAcesso: number;
  sitRegistro: number;
}

function AdicionarUsuario() {
  const [codPerfilAcesso, setTipoPerfil] = useState<any[]>([]);
  const [campoObrigatorio, setCampoObrigatorio] = useState<boolean>(false);
  const [isEmailValido, setEmailValido] = useState<boolean>(false);
  const [usuarios, setUsuarios] = useState<Usuario | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate()
  const { id } = useParams();

  useEffect(() => {
    const fetchTipoPerfis = async () => {
      setLoading(true);
      try {
        const data = await api.listarTiposPerfisAtivos();
        setTipoPerfil(data);
      } catch (error) {
        console.error('Erro ao buscar tipos de perfis ativos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTipoPerfis();
  }, []);

  const fetchObterUsuario = async () => {
    if (!id) return;

    try {
      const data = await api.obterUsuario(Number(id));
      setUsuarios(data);
      console.log('data', data);

      setFormValues({
        dscCpf: { value: data.dscCpf, error: false },
        dscNome: { value: data.dscNome, error: false },
        dscDdd: { value: data.dscDdd, error: false },
        dscCelular: { value: data.dscCelular, error: false },
        dscEmail: { value: data.dscEmail, error: false },
        dscSenha: { value: '', error: false },
        codPerfilAcesso: { value: data.codPerfilAcesso, error: false },
        sitRegistro: { value: data.sitRegistro, error: false }
      });

      const isEmailValido = emailRegex.test(data.dscEmail);
      setEmailValido(isEmailValido);
    } catch (error) {
      console.error('Erro ao obter usuario:', error);
    }
  };

  useEffect(() => {
    fetchObterUsuario();
  }, [id]);

  const [formValues, setFormValues] = useState({
    dscCpf: {
      value: '',
      error: false
    },
    dscNome: {
      value: '',
      error: false
    },
    dscDdd: {
      value: '',
      error: false
    },
    dscCelular: {
      value: '',
      error: false
    },
    dscEmail: {
      value: '',
      error: false
    },
    dscSenha: {
      value: '',
      error: false
    },
    codPerfilAcesso: {
      value: '',
      error: false
    },
    sitRegistro: {
      value: '',
      error: false
    },
  })

  const { ref: cpfRef } = useIMask(
    { mask: '000.000.000-00' },
    {
      onAccept: (value) => handleFieldChange('dscCpf', value)
    }
  );

  function mascaraCpf(cpf: string) {
    /* eslint-disable-next-line */
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
  }

  function mascaraCelular(celular: string) {
    const { dscDdd, dscCelular } = formValues;
    if (dscCelular.value && dscDdd.value) {
      return celular.replace(/(\d{5})(\d{4})/g, "$1-$2");
    }
    return celular;
  }

  /* eslint-disable-next-line */
  const senhaRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[#\$%!@]).{8,255}$/;

  const validarSenha = (senha: string) => {
    return senhaRegex.test(senha);
  };

  /* eslint-disable-next-line */
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;

  const isFormValid = () => {
    const { dscCpf, dscNome, dscDdd, dscCelular, dscEmail, dscSenha, codPerfilAcesso } = formValues;

    const isCelularObrigatorio = campoObrigatorio === true
      ? (dscDdd.value && dscDdd.value.length >= 2 && dscCelular.value && dscCelular.value.length >= 9)
      : true;

    const isEmailValido = emailRegex.test(dscEmail.value);

    /* eslint-disable-next-line */
    const senhaRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[#\$%!@]).{8,255}$/;

    const isSenhaValida = dscSenha.value === '' || senhaRegex.test(dscSenha.value);

    return (
      dscCpf.value && dscNome.value && dscEmail.value && isSenhaValida &&
      dscCpf.value.length >= 11 &&
      (dscNome.value.length >= 3 && dscNome.value.length <= 160) &&
      dscEmail.value.length <= 80 &&
      isEmailValido &&
      (codPerfilAcesso.value != null) &&
      isCelularObrigatorio
    );
  };


  const handleFieldChange = (field: any, value: any) => {
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    });

    if (field === 'dscEmail') {
      const isEmailValido = emailRegex.test(value);
      setEmailValido(isEmailValido);
    }

    if (field === 'dscSenha') {
      const isSenhaValida = validarSenha(value);
      setFormValues(prevState => ({
        ...prevState,
        dscSenha: {
          value,
          error: !isSenhaValida
        }
      }));
    }
  };

  const handleSubmit = async () => {
    const { dscCpf, dscNome, dscDdd, dscCelular, dscEmail, dscSenha, codPerfilAcesso, sitRegistro } = formValues;

    const senhaParaEnviar = dscSenha.value || usuarios?.dscSenha;

    const params = {
      dscCpf: dscCpf?.value ? dscCpf.value.replace(/\.|-/gm, '') : null,
      dscNome: dscNome.value || null,
      dscDdd: dscDdd?.value || null,
      dscCelular: dscCelular?.value ? dscCelular.value.replace(/\.|-/gm, '') : null,
      dscEmail: dscEmail.value || null,
      dscSenha: senhaParaEnviar,
      codPerfilAcesso: Number(codPerfilAcesso.value),
      sitRegistro: Number(sitRegistro.value),
    };

    try {
      await api.editarUsuario(Number(id), params);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Usuário editado com sucesso.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/configuracao/gerenciar-usuario');
      });
    } catch (error) {
      console.error('Erro ao editar usuário:', error);
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro!',
        text: 'Erro ao editar usuário. Tente novamente mais tarde.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/configuracao/gerenciar-usuario');
      });
    }
  };

  const validarObrigatoriedadeTelefone = async () => {
    const { dscDdd, dscCelular } = formValues;

    if ((dscDdd.value && dscDdd.value.length >= 1) || (dscCelular.value && dscCelular.value.length >= 1)) {
      setCampoObrigatorio(true);
    } else {
      setCampoObrigatorio(false);
    }
  }

  useEffect(() => {
    validarObrigatoriedadeTelefone();
  });

  useEffect(() => {
    const isEmailValido = emailRegex.test(formValues.dscEmail.value);
    setEmailValido(isEmailValido);
  }, [formValues.dscEmail.value]);

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Editar Usuário
      </Heading>
      {loading ? (
        <Flex
          justify="center"
          align="center"
          direction="column"
          w="70%"
          h="90%"
          position='fixed'
        >
          <Spinner size="xl" color="blue.500" />
        </Flex>
      ) : (
        <><div className="row mb-3">
          <div className="col-md-6 mb-4">
            <FormControl isRequired isInvalid={formValues.codPerfilAcesso.error}>
              <FormLabel mb="8px">Perfil de Usuário</FormLabel>
              <Select
                placeholder='Selecione o tipo de perfil'
                borderColor="gray.300"
                _hover={{ borderColor: 'blue.500' }}
                value={formValues.codPerfilAcesso.value}
                onChange={(e) => handleFieldChange('codPerfilAcesso', e.target.value)}
              >
                {codPerfilAcesso.length != null ? (
                  codPerfilAcesso.map(perfil => (
                    <option key={perfil.codPerfilAcesso} value={perfil.codPerfilAcesso}>
                      {perfil.dscPerfilAcesso}
                    </option>
                  ))
                ) : (
                  <option disabled>Sem tipos de perfis disponíveis</option>
                )}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-6 mb-4">
            <FormControl isRequired>
              <FormLabel mb="8px">CPF</FormLabel>
              <Input
                isReadOnly
                backgroundColor='#f5f5f5'
                cursor='not-allowed'
                variant="outline"
                borderColor="gray.300"
                _hover={{
                  borderColor: 'blue.500',
                }}
                _focus={{
                  borderColor: 'blue.500',
                  boxShadow: '0 0 0 1px blue.500',
                }}
                ref={cpfRef}
                value={mascaraCpf(formValues.dscCpf.value)}
                onChange={(e) => handleFieldChange('dscCpf', e.target.value.trim())}
                minLength={11}
                maxLength={60} />
            </FormControl>
          </div>
          <div className="col-md-6 mb-4">
            <FormControl isRequired>
              <FormLabel mb="8px">Nome</FormLabel>
              <CustomInput
                value={formValues.dscNome.value}
                onChange={(e) => handleFieldChange('dscNome', e.target.value)}
                maxLength={160}
                minLength={3} />
            </FormControl>
          </div>
          <div className="col-md-2 mb-4">
            <FormControl isRequired={campoObrigatorio}>
              <FormLabel mb="8px">DDD do celular</FormLabel>
              <CustomInput
                value={formValues.dscDdd.value}
                onChange={(e) => handleFieldChange('dscDdd', e.target.value)}
                minLength={2}
                maxLength={2} />
            </FormControl>
          </div>
          <div className="col-md-4 mb-4">
            <FormControl isRequired={campoObrigatorio}>
              <FormLabel mb="8px">Número do Celular</FormLabel>
              <Input
                value={mascaraCelular(formValues.dscCelular.value)}
                onChange={(e) => handleFieldChange('dscCelular', e.target.value.trim())}
                minLength={10}
                maxLength={10} />
            </FormControl>
          </div>
          <div className="col-md-6 mb-4">
            <FormControl isRequired isInvalid={!isEmailValido}>
              <FormLabel mb="8px">E-mail</FormLabel>
              <CustomInput
                type={'email'}
                value={formValues.dscEmail.value}
                onChange={(e) => handleFieldChange('dscEmail', e.target.value)}
                maxLength={80} />
              {!isEmailValido && (
                <FormErrorMessage>Por favor, insira um e-mail válido.</FormErrorMessage>
              )}
            </FormControl>
            <FormControl marginTop={'25px'} isRequired>
              <FormLabel mb="8px">Situação</FormLabel>
              <RadioGroup
                mt={3.5}
                onChange={(value) => handleFieldChange('sitRegistro', Number(value))}
                value={formValues.sitRegistro.value.toString()}
              >
                <Radio value="1" mr={5}>Ativo</Radio>
                <Radio value="0">Inativo</Radio>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="col-md-6 mb-4">
            <FormControl isRequired isInvalid={formValues.dscSenha.error}>
              <FormLabel mb="8px">Senha</FormLabel>
              <CustomInput
                type="password"
                value={formValues.dscSenha.value || ''}
                onChange={(e) => handleFieldChange('dscSenha', e.target.value)}
                minLength={8}
                maxLength={255}
                placeholder="********" />
              {formValues.dscSenha.error && (
                <FormErrorMessage>
                  A senha deve possuir no mínimo 8 caracteres, e deve conter letras, números e pelo menos um dos seguintes sinais: #$%!@.
                </FormErrorMessage>
              )}
              {!formValues.dscSenha.error && (
                <FormHelperText>
                  A senha deve possuir no mínimo 8 caracteres, necessário conter letras e números e pelo menos um dos seguintes sinais: #$%!@.
                </FormHelperText>
              )}
            </FormControl>
          </div>
        </div><Box alignItems={'flex-end'}>
            <Button type="submit" m="px" onClick={() => navigate('/cms/configuracao/gerenciar-usuario')}>
              Desistir
            </Button>
            <Button
              type="submit"
              m="6px"
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              onClick={() => {
                handleSubmit()
                // navigate(-1);
              }}
              isDisabled={!isFormValid()}
            >
              Editar Usuário
            </Button>
          </Box>
        </>
      )}
    </Flex>
  )
}

export default AdicionarUsuario;
