import { useState, useEffect } from 'react';
import { Flex, Heading, Button, FormControl, FormLabel, Stack, Checkbox, RadioGroup, Radio, Box } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import CustomInput from '../../../../components/common/Input/input';
import * as api from '../../../../api/perfil-usuario';
import { listarMenus } from '../../../../api/menu';
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb/SettingsBreadcrumb';

function EditarPerfilUsuario() {
  const [listaMenus, setListaMenus] = useState<any[]>([]);
  const [selectedMenus, setSelectedMenus] = useState<any[]>([]);
  const [originalMenus, setOriginalMenus] = useState<any[]>([]);
  const [isMenusChanged, setIsMenusChanged] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [formValues, setFormValues] = useState<any>({
    dscPerfilAcesso: { value: '', error: false },
    numRegistro: { value: 0, error: false },
    menus: { value: [], error: false }
  });

  const fetchNoticiaData = async () => {
    try {
      const userData = await api.obterPerfilUsuario(id);
      const { dscPerfilAcesso, numRegistro, menus } = userData;

      console.log('userdata', userData);

      setOriginalMenus(menus.map((item: any) => ({
        codMenu: item.codMenu.toString(),
        seqPerfilMenu: item.seqPerfilMenu || null
      })));
      setSelectedMenus(menus.filter((item: any) => item.numRegistro === 1).map((item: any) => item.codMenu.toString()));
      setFormValues({
        dscPerfilAcesso: { value: dscPerfilAcesso, error: false },
        numRegistro: { value: numRegistro, error: false },
      });
    } catch (error) {
      console.error("Erro ao buscar dados do perfil:", error);
    }
  };

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const listaMenus = await listarMenus();
        setListaMenus(listaMenus);
        console.log('Menus carregados:', listaMenus);
      } catch (error) {
        console.error('Erro ao listar menus:', error);
      }
    };

    fetchMenus();
    fetchNoticiaData();
  }, []);

  const handleCheckboxChange = (codMenu: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsMenusChanged(true);

    setSelectedMenus(prevSelectedMenus =>
      isChecked
        ? [...prevSelectedMenus, codMenu.toString()]
        : prevSelectedMenus.filter((id: string) => id !== codMenu.toString())
    );

    // console.log(`Checkbox ${codMenu} foi ${isChecked ? 'marcado' : 'desmarcado'}`);
  };

  const handleSelectAll = () => {
    setIsMenusChanged(true);
    const allmenus = listaMenus.map(menu => menu.codMenu.toString());
    setSelectedMenus(allmenus);
  };

  const handleDeselectAll = () => {
    setIsMenusChanged(true);
    setSelectedMenus([]);
  };

  const handleSubmit = async () => {
    const { dscPerfilAcesso, numRegistro } = formValues;
    let menus = null;

    if (isMenusChanged) {
      menus = listaMenus.map(menu => {
        const originalMenu = originalMenus.find(original => original.codMenu === menu.codMenu.toString());
        return {
          codMenu: menu.codMenu,
          numRegistro: selectedMenus.includes(menu.codMenu.toString()) ? 1 : 0,
          seqPerfilMenu: originalMenu ? originalMenu.seqPerfilMenu : null,
          codPerfilAcesso: Number(id),
        };
      });
    }

    const params = {
      codPerfilAcesso: Number(id),
      dscPerfilAcesso: dscPerfilAcesso.value || '',
      numRegistro: numRegistro.value,
      menus: menus || []
    };

    console.log('form enviado:', params);

    try {
      await api.editarPerfilUsuario(Number(id), params);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Usuário editado com sucesso.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/configuracao/gerenciar-perfil-usuario');
      });
    } catch (error) {
      console.error('Erro ao editar usuário:', error);
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro!',
        text: 'Erro ao editar usuário. Tente novamente mais tarde.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/configuracao/gerenciar-perfil-usuario');
      });
    }
  };

  const isFormValid = () => {
    const { dscPerfilAcesso } = formValues;
    return dscPerfilAcesso.value.length >= 3 && dscPerfilAcesso.value.trim();
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" mb="20px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Editar Perfil de Usuário
      </Heading>
      <div className="row mb-3">
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Perfil</FormLabel>
            <CustomInput
              value={formValues.dscPerfilAcesso.value}
              onChange={(e) => setFormValues({ ...formValues, dscPerfilAcesso: { value: e.target.value, error: false } })}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Situação</FormLabel>
            <RadioGroup
              mt={3.5}
              onChange={(value) => setFormValues({ ...formValues, numRegistro: { value: Number(value), error: false } })}
              value={formValues.numRegistro.value.toString()}
            >
              <Radio value="1" mr={5}>Ativo</Radio>
              <Radio value="0">Inativo</Radio>
            </RadioGroup>
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl>
            <Flex justify="space-between" align="center" mb="8px">
              <FormLabel mb="8px">
                Selecione quais menus este perfil pode ter acesso {/* <span style={{ color: 'red' }}>*</span> */}
              </FormLabel>
            </Flex>
            <Flex mb="16px">
              <Button onClick={handleSelectAll} mr="4">
                Marcar Todos
              </Button>
              {selectedMenus.length > 0 && (
                <Button onClick={handleDeselectAll}>
                  Desmarcar Todos
                </Button>
              )}
            </Flex>
            <div className="sidebar-checkbox-pagina">
              <Stack spacing={5}>
                {listaMenus.length > 0 ? (
                  listaMenus.map((menu: any) => (
                    <Flex key={menu.codMenu} align="center" justify="space-between" direction={{ base: 'column', md: 'row' }}>
                      <Checkbox
                        isChecked={selectedMenus.includes(menu.codMenu.toString())}
                        onChange={(e) => handleCheckboxChange(menu.codMenu, e)}
                      >
                        {menu.dscMenu}
                      </Checkbox>
                    </Flex>
                  ))
                ) : (
                  <p>Não há páginas disponíveis.</p>
                )}
              </Stack>
            </div>
          </FormControl>
        </div>
      </div>

      <Box alignItems={'flex-end'}>
        <Button m="px" onClick={() => navigate(-1)}>
          Desistir
        </Button>
        <Button
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={handleSubmit}
          isDisabled={!isFormValid()}
        >
          Editar Perfil de Usuário
        </Button>
      </Box>
    </Flex>
  );
}

export default EditarPerfilUsuario;
