import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Flex,
  Heading,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react'

import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb';
import TabelaAplicativo from './tabela-visualizacao-necessidade-atuacao';
// import { listarAplicacao } from '../../../api/aplicativo';
import Paginacao from '../../../../components/common/paginacao/paginacao';

const VisualizacaoNecesidadeAtuacao = () => {
  const [necesidadeAtuacao, setNecesidadeAtuacao] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate()

  useEffect(() => {    
    const fetchNecesidadeAtuacao = async () => {
      try {
        // const data = await listarAplicacao(currentPage, itemsPerPage);
        // console.log(data);
        // setNecesidadeAtuacao(data);
      } 
      catch (error) {
        console.error('Erro ao buscar NecesidadeAtuacao:', error);
      }
    };    

    fetchNecesidadeAtuacao();
  }, [currentPage, itemsPerPage]);

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        mb="60px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
      Avisos de necessidade de atuação      
    </Heading>

      <Box>
        <TabelaAplicativo 
          listaNecessidadeAtuacao={necesidadeAtuacao} 
          listaVazia="Nenhum dado disponível." 
        />
      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage}
      />
    </Flex>
  )
}

export default VisualizacaoNecesidadeAtuacao
