import { Flex, Heading, Button, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Box, RadioGroup, Radio } from '@chakra-ui/react'; 
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SettingsBreadcrumb from '../../../components/SettingsBreadcrumb';
import CustomInput from '../../../components/common/Input/input';
import * as api from '../../../api/redesSociais';
import Swal from 'sweetalert2';

function EditarRedeSocial() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  // const { descricaoRede } = location.state;
  // const { codigoRede } = location.state;

  const [formValues, setFormValues] = useState({
    dscUsuario: { value: '', error: false },
    dscTokenAcesso: { value: '', error: false },
    sitRegistro: { value: 0, error: false },
    dscSenha: { value: '', error: false },
    dscEndpoint: { value: '', error: false },
    dscChaveApi: { value: '', error: false },
  });

  const fetchRedesSociais = async () => {
    if (!id) return;

    try {
      const data = await api.obterContaRedeSocial(Number(id));
      setFormValues({
        dscUsuario: { value: data.dscUsuario, error: false },
        dscTokenAcesso: { value: data.dscTokenAcesso, error: false },
        sitRegistro: { value: data.sitRegistro, error: false },
        dscSenha: { value: data.dscSenha, error: false },
        dscEndpoint: { value: data.dscEndpoint, error: false },
        dscChaveApi: { value: data.dscChaveApi, error: false },
      });
    } catch (error) {
      console.error('Erro ao obter rede social:', error);
    }
  };

  useEffect(() => {
    fetchRedesSociais();
  }, [id]);

  const isFormValid = () => {
    const { dscUsuario } = formValues;
    return dscUsuario.value.trim().length >= 5;
  };

  const handleFieldChange = (field: any, value: any) => {
    setFormValues({
      ...formValues,
      [field]: { error: false, value }
    });
  };

  const handleSubmit = async () => {
    const { dscUsuario, dscTokenAcesso, sitRegistro, dscSenha, dscEndpoint, dscChaveApi } = formValues;

    const params = {
      dscUsuario: dscUsuario.value.trim() !== '' ? dscUsuario.value : null,
      dscTokenAcesso: dscTokenAcesso.value || null,
      sitRegistro: sitRegistro.value,
      dscSenha: dscSenha.value,
      dscEndpoint: dscEndpoint.value,
      dscChaveApi: dscChaveApi.value
    };
  
    try {
      await api.editarContaRedeSocial(Number(id), params);
      // setIsSuccessModalOpen(true);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Rede Social editada com sucesso.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        setIsSuccessModalOpen(false);
        navigate(-1)      
      });
    } catch (error) {
      console.error('Erro ao editar rede social:', error);

      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Erro ao editar rede social. Tente novamente mais tarde.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        setIsSuccessModalOpen(false);
      });
    }
  };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" mb="20px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Editar Rede Social
      </Heading>
      <div className="row mb-3">
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Nome do Usuário</FormLabel>
            <CustomInput
              value={formValues.dscUsuario.value}
              onChange={(e) => handleFieldChange('dscUsuario', e.target.value)}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Senha do Usuário</FormLabel>
            <CustomInput
              type={'password'}
              value={formValues.dscSenha.value}
              onChange={(e) => handleFieldChange('dscSenha', e.target.value)}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Endpoint da API</FormLabel>
            <CustomInput
              value={formValues.dscEndpoint.value}
              onChange={(e) => handleFieldChange('dscEndpoint', e.target.value)}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Token de Acesso</FormLabel>
            <CustomInput
              value={formValues.dscTokenAcesso.value}
              onChange={(e) => handleFieldChange('dscTokenAcesso', e.target.value)}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Chave de API</FormLabel>
            <CustomInput
              value={formValues.dscChaveApi.value}
              onChange={(e) => handleFieldChange('dscChaveApi', e.target.value)}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Situação</FormLabel>
            <RadioGroup mt={3.5} onChange={(value) => handleFieldChange('sitRegistro', Number(value))} value={formValues.sitRegistro.value.toString()}>
              <Radio value="1" mr={5}>Ativo</Radio>
              <Radio value="0">Inativo</Radio>
            </RadioGroup>
          </FormControl>
        </div>
      </div>

      <Box alignItems={'flex-end'}>
        <Button m="px" onClick={() => navigate(-1)}>
          Desistir
        </Button>
        <Button
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={handleSubmit}
          isDisabled={!isFormValid()}
        >
          Editar Rede Social
        </Button>
      </Box>

      <Modal isOpen={isSuccessModalOpen} onClose={() => {
        setIsSuccessModalOpen(false);
        navigate('/cms/noticia');
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Rede Social editada com sucesso!</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => {
              setIsSuccessModalOpen(false);
              navigate(-1)
            }}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atenção!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{errorMessage}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setIsErrorModalOpen(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default EditarRedeSocial;
