import { MdEdit } from 'react-icons/md'

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Icon,
  IconButton,
  Spinner,
  Center,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

type Categoria = {
  id: number
  nomeAplicativo: string
  ativo: boolean
}

interface TabelaAplicativoProps {
  listaAplicativo: Categoria[]
  listaVazia: string;
  loading: boolean;
}

const TabelaAplicativo = ({ listaAplicativo, listaVazia, loading }: TabelaAplicativoProps) => {
  const navigate = useNavigate();

  const renderRows = () =>
    listaAplicativo.length > 0 ? (
    listaAplicativo.map((aplicativo) => (
      <Tr key={aplicativo.id}>
        <Td>{aplicativo.id}</Td>
        <Td>{aplicativo.nomeAplicativo}</Td>
        <Td>{aplicativo.ativo ? 'Ativo' : 'Inativo'}</Td>
        <Td>
          <IconButton aria-label="Botão Editar" bgColor="#fff">
            <Icon 
              as={MdEdit} 
              fontSize="24px" 
              color="#1789FC" 
              onClick={() => navigate(`editar-aplicativo/${aplicativo.id}`)}
              />
          </IconButton>
        </Td>
      </Tr>
    )) 
    ) : (
      <Tr>
        <Td colSpan={5} textAlign="center">{listaVazia}</Td>
      </Tr>
    );

  return (
    <TableContainer>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Nome do Aplicativo</Th>
            <Th>Ativo</Th>
            <Th>Editar</Th>
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            <Tr>
              <Td colSpan={5}>
                <Center>
                  <Spinner size="lg" color="blue.500" />
                </Center>
              </Td>
            </Tr>
          ) : (
            renderRows()
          )}
        </Tbody>          
      </Table>
    </TableContainer>
  )
}

export default TabelaAplicativo
