import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Flex,
  Heading,
  Box,
  Button,
  Select,
  HStack,
  Input
} from '@chakra-ui/react'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import TabelaPerfilUsuario from '../tabela-usuario/tabela-perfil-usuario'
import Paginacao from '../../../../components/common/paginacao/paginacao'
import * as api from '../../../../api/perfil-usuario'

const GerenciarPerfilUsuario = () => {
  const [usuarios, setUsuarios] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate()

  useEffect(() => {    
    const fetchUsuarios = async () => {
      setLoading(true);
      try {
        const data = await api.listarPerfisUsuarios(currentPage, itemsPerPage);
        console.log(data);
  
        setUsuarios(data.formatoArquivos.map((usuario: any) => ({
          codPerfilAcesso: usuario.codPerfilAcesso,
          dscPerfilAcesso: usuario.dscPerfilAcesso,
          numRegistro: usuario.numRegistro,
        })));
  
      } catch (error) {
        console.error('Erro ao buscar usuários:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUsuarios();
  }, [currentPage, itemsPerPage]);

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Gerenciar Perfil de Usuário
      </Heading>


      {/* Search bar */}
      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          
          {/* <Flex dir="inline" w="70%">
            <Select defaultValue="cpf" w="20%">
              <option value="cpf">CPF</option>
              <option value="nome">Nome</option>
            </Select>

            <Input ml="12px" placeholder="Digite Aqui" />
          </Flex> */}
        </HStack>
      </Box>
      <Box>
      <TabelaPerfilUsuario 
        listaPerfilUsuario={usuarios} 
        listaVazia="Nenhum dado disponível."
        loading={loading}
      />

      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage}
      />
      {/* Table */}
    </Flex>
  )
}

export default GerenciarPerfilUsuario;
