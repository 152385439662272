import { Flex, Heading, Button, Input, Box, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Select, FormErrorMessage, FormHelperText } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import CustomInput from '../../../../components/common/Input/input'
import { useIMask } from 'react-imask';
import * as api from '../../../../api/usuario';
import Swal from 'sweetalert2'

function AdicionarUsuario() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [codPerfilAcesso, setTipoPerfil] = useState<any[]>([]);
  const [campoObrigatorio, setCampoObrigatorio] = useState<boolean>(false);
  const [isCpfValido, setIsCpfValido] = useState<boolean>(false);
  const [isEmailValido, setEmailValido] = useState<boolean>(false);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchTipoPerfis = async () => {
      try {
        const data = await api.listarTiposPerfisAtivos();
        setTipoPerfil(data);
      } catch (error) {
        console.error('Erro ao buscar tipos de perfis ativos:', error);
      }
    };

    fetchTipoPerfis();
  }, []);

  const [formValues, setFormValues] = useState({
    dscCpf: {
      value: '',
      error: false
    },
    dscNome: {
      value: '',
      error: false
    },
    dscDdd: {
      value: '',
      error: false
    },
    dscCelular: {
      value: '',
      error: false
    },
    dscEmail: {
      value: '',
      error: false
    },
    dscSenha: {
      value: '',
      error: false
    },
    codPerfilAcesso: {
      value: '',
      error: false
    },
  })

  const { ref: cpfRef } = useIMask(
    { mask: '000.000.000-00' },
    {
      onAccept: (value) => handleFieldChange('dscCpf', value)
    }
  );

  const { ref: celularRef } = useIMask(
    { mask: '00000-0000' },
    {
      onAccept: (value) => handleFieldChange('dscCelular', value)
    }
  );

  /* eslint-disable-next-line */
  const senhaRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[#\$%!@]).{8,255}$/;

  const validarSenha = (senha: string) => {
    return senhaRegex.test(senha);
  };

  /* eslint-disable-next-line */
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;

  const validarCpf = async (cpf: string) => {
    try {
      const data = await api.validarCpf(cpf);
      console.log('data', data);
      setIsCpfValido(true);

      Swal.fire({
        icon: 'warning',
        title: 'CPF já cadastrado!',
        html: 'Este CPF já está cadastrado na plataforma. </br> Deseja ir para a página de edição deste usuário?',
        showCancelButton: true,
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'Editar usuário',
        cancelButtonText: 'Desistir',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/cms/configuracao/gerenciar-usuario/editar-usuario/${data.seqUsuario}`);
        }
        else if (result.dismiss === Swal.DismissReason.cancel) {
          navigate('/cms/configuracao/gerenciar-usuario');
        }
      });
    } catch (error) {
      setIsCpfValido(false);
      console.error('cpf não encontrado:', error);
    }
  };

  const isFormValid = () => {
    const { dscCpf, dscNome, dscDdd, dscCelular, dscEmail, dscSenha, codPerfilAcesso } = formValues;
    const isCelularObrigatorio = campoObrigatorio == true ? (dscDdd.value.length >= 2 && dscCelular.value.length >= 9) : true;
    const isEmailValido = emailRegex.test(dscEmail.value);

    return (
      dscCpf.value && dscNome.value && dscEmail.value && dscSenha.value &&
      dscCpf.value.length >= 12 &&
      (dscNome.value.length >= 3 && dscNome.value.length <= 160) &&
      dscEmail.value.length <= 80 &&
      isEmailValido &&
      dscSenha.value.length >= 8 &&
      dscSenha.value.length <= 255 &&
      validarSenha(dscSenha.value) &&
      (codPerfilAcesso.value != null) &&
      isCelularObrigatorio
    );
  };

  const handleFieldChange = (field: any, value: any) => {
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    });

    if (field === 'dscEmail') {
      const isEmailValido = emailRegex.test(value);
      setEmailValido(isEmailValido);
    }

    if (field === 'dscSenha') {
      const isSenhaValida = validarSenha(value);
      setFormValues(prevState => ({
        ...prevState,
        dscSenha: {
          value,
          error: !isSenhaValida
        }
      }));
    }
  };


  const handleSubmit = async () => {
    const { dscCpf, dscNome, dscDdd, dscCelular, dscEmail, dscSenha, codPerfilAcesso } = formValues;

    const params = {
      dscCpf: dscCpf.value.replace(/\.|-/gm, '') || null,
      dscNome: dscNome.value || null,
      dscDdd: dscDdd.value || null,
      dscCelular: dscCelular.value.replace(/\.|-/gm, '') || null,
      dscEmail: dscEmail.value || null,
      dscSenha: dscSenha.value || null,
      codPerfilAcesso: Number(codPerfilAcesso.value)
    };

    try {
      await api.inserirUsuario(params);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        html: 'Usuário adicionado com sucesso.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/configuracao/gerenciar-usuario');
      });
    } catch (error) {
      setErrorMessage('Houve um problema ao adicionar o usuário. Tente novamente.');
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu um erro',
        html: 'Não conseguimos adicionar o usuário no momento. Por favor, tente novamente mais tarde.',
        confirmButtonColor: '#1789FC',
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/cms/configuracao/gerenciar-usuario');
      });
    }
  };

  const validarObrigatoriedadeTelefone = async () => {
    const { dscDdd, dscCelular } = formValues;
    dscDdd.value.length >= 1 || dscCelular.value.length >= 1 ? setCampoObrigatorio(true) : setCampoObrigatorio(false);
  }

  useEffect(() => {
    validarObrigatoriedadeTelefone();
  });

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Adicionar Usuário
      </Heading>
      <div className="row mb-3">
        <div className="col-md-6 mb-4">
          <FormControl isRequired isInvalid={formValues.codPerfilAcesso.error}>
            <FormLabel mb="8px">Perfil de Usuário</FormLabel>
            <Select
              placeholder='Selecione o tipo de perfil'
              borderColor="gray.300"
              _hover={{ borderColor: 'blue.500' }}
              value={formValues.codPerfilAcesso.value}
              onChange={(e) => handleFieldChange('codPerfilAcesso', e.target.value)}
            >
              {codPerfilAcesso.length != null ? (
                codPerfilAcesso.map(perfil => (
                  <option key={perfil.codPerfilAcesso} value={perfil.codPerfilAcesso}>
                    {perfil.dscPerfilAcesso}
                  </option>
                ))
              ) : (
                <option disabled>Sem tipos de perfis disponíveis</option>
              )}
            </Select>
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">CPF</FormLabel>
            <Input
              backgroundColor={'#fff'}
              variant="outline"
              borderColor="gray.300"
              _hover={{
                borderColor: 'blue.500',
              }}
              _focus={{
                borderColor: 'blue.500',
                boxShadow: '0 0 0 1px blue.500',
              }}
              ref={cpfRef}
              value={formValues.dscCpf.value}
              onBlur={() => validarCpf(formValues.dscCpf.value.replace(/\.|-/gm, ''))}
              onChange={(e) => handleFieldChange('dscCpf', e.target.value.trim())}
              minLength={11}
              maxLength={60}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired>
            <FormLabel mb="8px">Nome</FormLabel>
            <CustomInput
              value={formValues.dscNome.value}
              onChange={(e) => handleFieldChange('dscNome', e.target.value)}
              maxLength={160}
              minLength={3}
            />
          </FormControl>
        </div>
        <div className="col-md-2 mb-4">
          <FormControl isRequired={campoObrigatorio}>
            <FormLabel mb="8px">DDD do celular</FormLabel>
            <CustomInput
              value={formValues.dscDdd.value}
              onChange={(e) => handleFieldChange('dscDdd', e.target.value)}
              minLength={2}
              maxLength={2}
            />
          </FormControl>
        </div>
        <div className="col-md-4 mb-4">
          <FormControl isRequired={campoObrigatorio}>
            <FormLabel mb="8px">Número do Celular</FormLabel>
            <Input
              ref={celularRef}
              value={formValues.dscCelular.value.trim()}
              onChange={(e) => handleFieldChange('dscCelular', e.target.value.trim())}
              minLength={10}
              maxLength={10}
            />
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired isInvalid={!isEmailValido}>
            <FormLabel mb="8px">E-mail</FormLabel>
            <CustomInput
              type={'email'}
              value={formValues.dscEmail.value}
              onChange={(e) => handleFieldChange('dscEmail', e.target.value)}
              maxLength={80}
            />
            {!isEmailValido && (
              <FormErrorMessage>Por favor, insira um e-mail válido.</FormErrorMessage>
            )}
          </FormControl>
        </div>
        <div className="col-md-6 mb-4">
          <FormControl isRequired isInvalid={formValues.dscSenha.error}>
            <FormLabel mb="8px">Senha</FormLabel>
            <CustomInput
              type={'password'}
              value={formValues.dscSenha.value}
              onChange={(e) => handleFieldChange('dscSenha', e.target.value)}
              minLength={8}
              maxLength={255}
            />
            {formValues.dscSenha.error && (
              <FormErrorMessage>
                A senha deve possuir no mínimo 8 caracteres, e deve conter letras, números e pelo menos um dos seguintes sinais: #$%!@.
              </FormErrorMessage>
            )}
            {!formValues.dscSenha.error && (
              <FormHelperText>
                A senha deve possuir no mínimo 8 caracteres, necessário conter letras e números e pelo menos um dos seguintes sinais: #$%!@.
              </FormHelperText>
            )}
          </FormControl>
        </div>

      </div>
      <Box alignItems={'flex-end'}>
        <Button type="submit" m="px" onClick={() => navigate(-1)}>
          Desistir
        </Button>
        <Button
          type="submit"
          m="6px"
          color="#FFF"
          bgColor="#1665D8"
          _hover={{ opacity: 0.8 }}
          onClick={() => {
            handleSubmit();
            // navigate(-1);
          }}
          isDisabled={!isFormValid()}
        >
          Adicionar Usuário
        </Button>
      </Box>

      <Modal isOpen={isSuccessModalOpen} onClose={() => {
        setIsSuccessModalOpen(false);
        navigate('/cms/configuracao/gerenciar-usuario');
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Usuário adicionado com sucesso!</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => {
              setIsSuccessModalOpen(false);
              navigate('/cms/configuracao/gerenciar-usuario');
            }}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isErrorModalOpen} onClose={() => setIsErrorModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atenção!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{errorMessage}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={() => setIsErrorModalOpen(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default AdicionarUsuario;
