import { api } from './';

interface ApiError {
  response?: {
    data: any;
    status: number;
    statusText: string;
  };
}

export const editarMenu = async (id: number, params: any) => {
  try {
    const headers = {
      'Content-Type': 'application/json',
    };
    const response = await api.put(`/menus/editar/${id}`, params, {headers});
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao editar menu');
  }
};

export const obterMenu = async (id: number) => {
  try {
    const response = await api.get(`/menus/obter/${id}`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao obter menu');
  }
};

export const listarMenus = async () => {
  try {
    const response = await api.get(`/menus/listar`);
    return response.data;
  } catch (error) {
    const apiError = error as ApiError;
    throw apiError.response ? apiError.response.data : new Error('Erro ao listar menus');
  }
};