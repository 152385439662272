import { api } from './'

const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.eiconbrasil.com.br/Codeciphers-'
    : 'https://apihomolog-v2.codeciphers.com/';

export const auth = async (grant_type: any, tipoLogin: number, username: string, password: string) => {
  return await api.post(`${API_URL}CCLoginUnico/v2/token`, {
    grant_type,
    username: username,
    password,
    tipoLogin,
  });
};
