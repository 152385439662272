  import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
    styles: {
      global: {
        body: {
          bg: "#fafafa",
        },
      },
    },
  });
  
  export default theme;
  
