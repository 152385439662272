import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Heading,
  Box,
  Button,
  HStack,
} from '@chakra-ui/react';
import SettingsBreadcrumb from '../../components/SettingsBreadcrumb';
import TabelaBanner from './tabelaBanner/tabela-banner';
import Paginacao from '../../components/common/paginacao/paginacao';
import * as api from '../../api/banner';

const Banner = () => {
  const [banner, setBanner] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tamanhoPagina, setTamanhoPagina] = useState(10);
  const [loading, setLoading] = useState<boolean>(false);
  const [codTipoPublicacao, setcodTipoPublicacao] = useState(3);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBanner = async () => {
      setLoading(true);
      try {
        console.log('codTipoPublicacao', codTipoPublicacao);
        const data = await api.listarBanner(codTipoPublicacao, currentPage, tamanhoPagina);
  
        const banners = data.formatoArquivos.map((banner: any) => ({
          seqBanner: banner.seqConteudo,
          dscBanner: banner.nomePublicacao,
          datRegistro: banner.datRegistro,
          dataPublicacao: banner.datPublicacao,
          dataFimPublicacao: banner.datDespublicar || '',
        }));
  
        setBanner(banners);
        setTotalPages(data.totalPaginas || 1);
      } catch (error) {
        console.error('Erro ao buscar banners:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchBanner();
  }, [currentPage, tamanhoPagina]);
  

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading mt="30px" fontWeight="600" fontSize="21px" color="#6F6F6F" w="100%">
        Banner
      </Heading>

      <Box m="40px 0" w="100%">
        <HStack spacing={5}>
          <Button
            color="#fff"
            bgColor="#1789FC"
            onClick={() => navigate('novo-banner')}
          >
            Novo Banner
          </Button>
        </HStack>
      </Box>

      <Box>
        <TabelaBanner 
          listaBanner={banner} 
          listaVazia="Nenhum dado disponível."
          loading={loading}
        />
      </Box>

      <Paginacao 
        currentPage={currentPage} 
        totalPages={totalPages} 
        onPageChange={setCurrentPage} 
      />
    </Flex>
  );
};

export default Banner;
