import { Flex, Heading, Button, Grid, GridItem, Input, Box, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import SettingsBreadcrumb from '../../../../components/SettingsBreadcrumb'
import CustomInput from '../../../../components/common/Input/input'
import { inserirAplicacao } from '../../../../api/aplicativo'
import Swal from 'sweetalert2'

function NovoAplicativo() {
  const navigate = useNavigate()

  const [formValues, setFormValues] = useState({
    dscAplicativo: {
      value: '',
      error: false
    },
    dscToken: {
      value: '',
      error: false
    }
  })

  const isFormValid = () => {
    const { dscAplicativo } = formValues;
    const { dscToken } = formValues;
    return (
      dscAplicativo.value && dscToken.value).trim() && 
      dscAplicativo.value.length >= 5 && dscToken.value.length >= 5
  };

  const handleFieldChange = (field: string, value: string) =>
    setFormValues({
      ...formValues,
      [field]: {
        error: false,
        value
      }
    })

    const handleSubmit = async () => {
      const { dscAplicativo } = formValues;
      const { dscToken } = formValues;
  
      const params = {
        dscAplicativo: dscAplicativo.value || null,
        dscToken: dscToken.value || null,
      };

      try {
        await inserirAplicacao(params);
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Aplicativo inserido com sucesso.',
          confirmButtonColor: '#1789FC',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate('/cms/configuracao/aplicativo');
        });
      } catch (error) {
        console.error('Erro ao inserir aplicativo:', error);
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu um erro!',
          text: 'Erro ao inserir aplicativo. Tente novamente mais tarde.',
          confirmButtonColor: '#1789FC',
          confirmButtonText: 'OK',
        }).then(() => {
          navigate('/cms/configuracao/aplicativo');
        });
      }
    };

  return (
    <Flex p="40px" bgColor="#fafafa" direction="column" w="100%">
      <SettingsBreadcrumb />
      <Heading
        mt="30px"
        mb="20px"
        fontWeight="600"
        fontSize="21px"
        color="#6F6F6F"
        w="100%"
      >
        Incluir Novo Aplicativo
      </Heading>
        <div className="row mb-3">
          <div className="col-md-6 mb-4">
          <FormControl isRequired>
          <FormLabel mb="8px">Nome do Aplicativo</FormLabel>
            <CustomInput
              value={formValues.dscAplicativo.value}
              onChange={(e) => handleFieldChange('dscAplicativo', e.target.value)}
              maxLength={60}
            />
          </FormControl>
          </div>
          <div className="col-md-6 mb-4">
          <FormControl isRequired>
          <FormLabel mb="8px">Token do Aplicativo</FormLabel>
            <CustomInput
              value={formValues.dscToken.value}
              onChange={(e) => handleFieldChange('dscToken', e.target.value)}
              maxLength={60}
            />
          </FormControl>
          </div>
        </div>

          <Box alignItems={'flex-end'}>
            <Button type="submit" m="px" onClick={() => navigate(-1)}>
              Desistir
            </Button>
            <Button
              type="submit"
              m="6px"
              color="#FFF"
              bgColor="#1665D8"
              _hover={{ opacity: 0.8 }}
              onClick={() => {
                handleSubmit();
                // navigate(-1);
              }}
              isDisabled={!isFormValid()}
            >
              Adicionar Aplicativo
            </Button>
          </Box>
    </Flex>
  )
}

export default NovoAplicativo;
